import { makeStyles } from '@material-ui/core/styles';

const getWidth = (props) => {
  if (props.width) {
    return props.width;
  } else {
    if (props.minRows !== undefined) {
      return '100%'; // es multiline
    } else {
      return 'var(--ibs_control_width_lg)';
    }
  }
}

export const useStyles = makeStyles({
  root: {
    "&.MuiFormLabel-root": { // es la etiqueta.
      color: 'var(--ibs_color_unfocused)',
      fontFamily: 'var(--ibs_control_label_font_family)',
      fontSize: 'var(--ibs_control_label_font_size)',
      textAlign: 'left',
      /* background: 'beige', */
      padding: '0px 0px 0px 0px',
      width: '133%', // 2024-01-10 - daq: para darle a la etiqueta el mayor ancho posible.
      "&.MuiFormLabel-root.Mui-focused": {
        color: 'var(--ibs_color_focused)',
        //fontWeight: 'bold',
      },
    },
    "&.MuiInputBase-root": { // es la parte donde se escribe.
      "& .MuiInputBase-input": {
        /* background: 'yellow', */
        width: '100%', 
        padding: '5px 0px 0px 0px',
        fontFamily: 'var(--ibs_control_text_font_family)',
        fontSize: 'var(--ibs_control_text_font_size)',
      },
    },
  },

  control: {
    margin: '0px 0px 0px 0px',
    width: '100%',
  },
  frame: {
    /* background: 'var(--ibs_color_white)', */
    border: '1px solid var(--ibs_color_gray_light)',
    padding: '0px',
    borderRadius: '3px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    width: props => getWidth(props),
  },
  frame_content: {
    padding: '4px',
    width: '100%',
  },
  frame_buttons: {
    borderLeft: '1px solid var(--ibs_color_gray_light)',
    width: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  frame_icons: {
    margin: '0px',
    padding: '0px 0px 0px 0px',
    backgroundColor: 'transparent',
    color: 'var(--ibs_color_gray)',
    '& svg': {
      fontSize: 20,
    },
    /*
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    */
  },
  frame_icons_new: {
    margin: '0px',
    padding: '0px 0px 0px 0px',
    backgroundColor: 'transparent',
    color: 'var(--ibs_color_gray)',
    /*
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    */
  },

});
