import React, { useRef } from 'react';
import CxDate from '../../cx/CxDate';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxChooser from '../../cx/CxChooser';
import CxTableAttachmentBlob from '../../cx/CxTableAttachmentBlob';
import GxForm from '../../gx/GxForm';
//import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import { StoreMultimedia, getStoreValue, isApp } from "../../gx/GxStore";
//import { useAccess } from '../../hx/useAccess';
import { useSessionStorage } from '../../hx/useSessionStorage';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';

const MxMultimediaForm = (props) => {
  const [{ trans }] = useTranslation();
  //const [{ navBack }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storeMultimedia, setStoreMultimedia] = useSessionStorage('StoreMultimedia', StoreMultimedia);
  
  let r_form = React.useRef(null);
  let r_table_attachment_blob = React.useRef(null);

  const rf_date = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      
      // para poder guardar adjuntos cuando el registro es nuevo. 
      // se complementa en el servidor con LXXX.recSave(), que cuando es un registro nuevo, lo crea artificialmente para luego actualizarlo.
      // los registros que pudieran quedar huérfanos de n_xxx_blob porque el usuario se fué luego de adjuntar y sin guardar, se borrarán en el servidor LMaintenance.doOps().
      storeMultimedia.inMultimedia = FxStr.getRandomID();
      Promise.all([setStoreMultimedia(storeMultimedia)]).then((result) => {
        r_form.current.setPropFilter('inMultimedia', storeMultimedia.inMultimedia);
        r_table_attachment_blob.current.setInXxx(storeMultimedia.inMultimedia);
      });

    }
  }
  const formSave = async (lastSaveRv) => {
    // se guardó exitosamente
    storeMultimedia.inMultimedia = lastSaveRv.theValue;
    Promise.all([setStoreMultimedia(storeMultimedia)]).then((result) => {
      forceUIUpdate(); // 2024-03-06 - daq: para que muestre botón delete
      //navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.multimedia_archieve')}: ${trans('field.item')}`}
        xref={r_form}
        url="multimedia"
        filterIdField='inMultimedia'
        filterIdValue={storeMultimedia.inMultimedia}
        onFormLoad={formLoad}
        onFormSave={formSave}
        onFormDelete={formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="n_multimedia.txLinkTable" value={'n_branch'} />
        <CxHidden xref={useRef(null)} dbfield="n_multimedia.txLinkId" value={getStoreValue('StoreSession', 'inBranch')} />

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_multimedia.dtMultimedia`} required />

        <CxChooser xref={useRef(null)}
          label={trans('field.type')}
          dbfield="t_multimedia.txTypeMultimedia" dbfieldkey="n_multimedia.itMultimedia"
          table="t_multimedia"
          col_id="itMultimedia"
          col_tx="txTypeMultimedia"
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.issue')} dbfield="n_multimedia.txIssue" maxLength={100} required />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_multimedia.txReference" maxLength={50} />

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_multimedia.txNotes" minRows={4} speech />
        
        <CxTableAttachmentBlob xref={r_table_attachment_blob}
          inClient={storeMultimedia.inClient} 
          inXxx={storeMultimedia.inMultimedia} 
          url={'multimedia'} 
          table={'n_multimedia_blob'} 
          fld_inXxx={'inMultimedia'} 
          fld_inXxxBlob={'inMultimediaBlob'} 
          fld_txXxxBlob={'txMultimediaBlob'} 
          printable
        />

      </GxForm>

    </div>
  );

}

export default MxMultimediaForm;
