import { FaFileExport } from "react-icons/fa"; 
import React from 'react';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSnackbar from '../../cx/CxSnackbar';
import CxNumber from '../../cx/CxNumber';
import CxDate from '../../cx/CxDate';
import CxState from '../../cx/CxState';
import CxTableMem from '../../cx/CxTableMem';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxFetch from '../../fx/FxFetch';
import * as FxTable from '../../fx/FxTable';
import * as FxInet from '../../fx/FxInet';
import { StoreRxAuto, } from "../../gx/GxStore";
import { getStoreValue, getStoreValueBo, StoreClient, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxHidden from '../../cx/CxHidden';
import CxChooser from '../../cx/CxChooser';
import CxSearcher from '../../cx/CxSearcher';
import { useAccess } from '../../hx/useAccess';
import CxDialog from '../../cx/CxDialog';
import CxBackdrop from '../../cx/CxBackdrop';
import CxIconButton from '../../cx/CxIconButton';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxDlgCertificate from '../../cx/CxDlgCertificate';
import * as FxEdition from '../../fx/FxEdition';
import CxDlgUpgrade from '../../cx/CxDlgUpgrade';
import CxStatic from '../../cx/CxStatic';


const MxAnimalRxAutoForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.rxs'));
  const [{ navTo, navBack }] = useNav();

  const [storeClient, ] = useSessionStorage('StoreClient', StoreClient);

  const r_snack_ask_save = React.useRef(null);
  const r_snack_save = React.useRef(null);
  const r_snack_delete = React.useRef(null);
  const r_snack_error = React.useRef(null);
  const r_snack_success = React.useRef(null);

  const r_form = React.useRef(null);
  let r_print = React.useRef(null);
  let r_dlg_upgrade = React.useRef(null);

  const rf_print_preview = React.useRef(null);
  const rf_print_cert_sign = React.useRef(null);
  const rf_print_copies = React.useRef(null);

  const rf_inRxAuto = React.useRef(null);
  const rf_txRxAuto = React.useRef(null);
  const rf_dtRxAuto = React.useRef(null);

  const rf_txEmployee = React.useRef(null);
  const rf_txTypeSeriesRx = React.useRef(null);
  const rf_txNumberAnimals = React.useRef(null);
  const rf_txIdentification = React.useRef(null);
  const rf_txNumberRefills = React.useRef(null);
  const rf_boExceptional = React.useRef(null);
  const rf_txDaysValidity = React.useRef(null);
  const rf_txNotes = React.useRef(null);

  const rf_table_items = React.useRef(null);
  const r_dlg_alert = React.useRef(null);
  const r_dlg_certificate = React.useRef(null);

  const [dlgPrint, setDlgPrint] = React.useState(false);
  const [dlgDelete, setDlgDelete] = React.useState(false);
  const [dlgExit, setDlgExit] = React.useState(false);

  const [certificate, setCertificate] = React.useState({});

  const [backdrop, setBackdrop] = React.useState(false);

  const [storeRxAuto, setStoreRxAuto] = useSessionStorage('StoreRxAuto', StoreRxAuto);

  // Es el txRxAuto. No puedo usar storeRxAuto.txRxAuto porque al cambiarlo, react no lo vé como un cambio ya que lo que mantiene es la referencia al objeto storeClient.
  // eslint-disable-next-line no-unused-vars
  const [currentID, setCurrentID] = React.useState(storeRxAuto.txRxAuto);

  const isSpain = () => { return Boolean(getStoreValue('StoreSession', 'txIdi').startsWith('es.') ||getStoreValue('StoreSession', 'txIdi').startsWith('xx.') ) }

  React.useEffect(() => {
    formLoadCustom();
    // eslint-disable-next-line
  }, []);

  const sendStoreToForm = () => {
    try {

      if (rf_txEmployee.current) rf_txEmployee.current.setKey(storeRxAuto.inEmployee);
      if (rf_txEmployee.current) rf_txEmployee.current.setVal(storeRxAuto.txEmployee);
      if (rf_txTypeSeriesRx.current) rf_txTypeSeriesRx.current.setKey(storeRxAuto.itSeriesRx);
      if (rf_txTypeSeriesRx.current) rf_txTypeSeriesRx.current.setVal(storeRxAuto.txTypeSeriesRx);
      if (rf_dtRxAuto.current) rf_dtRxAuto.current.setVal(storeRxAuto.dtRxAuto);
      if (rf_txRxAuto.current) rf_txRxAuto.current.setVal(storeRxAuto.txRxAuto);
      if (rf_txNumberAnimals.current) rf_txNumberAnimals.current.setVal(storeRxAuto.txNumberAnimals);
      if (rf_txIdentification.current) rf_txIdentification.current.setVal(storeRxAuto.txIdentification);
      if (rf_txNumberRefills.current) rf_txNumberRefills.current.setVal(storeRxAuto.txNumberRefills);
      if (rf_boExceptional.current) rf_boExceptional.current.setVal(storeRxAuto.boExceptional);
      if (rf_txDaysValidity.current) rf_txDaysValidity.current.setVal(storeRxAuto.txDaysValidity);
      if (rf_txNotes.current) rf_txNotes.current.setVal(storeRxAuto.txNotes);

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.sendStoreToForm', error)
    }
  }

  const sendFormToStore = async () => {
    try {

      if (rf_txEmployee.current) storeRxAuto.inEmployee = rf_txEmployee.current.getKey();
      if (rf_txEmployee.current) storeRxAuto.txEmployee = rf_txEmployee.current.getVal();
      if (rf_txTypeSeriesRx.current) storeRxAuto.itSeriesRx = rf_txTypeSeriesRx.current.getKey();
      if (rf_txTypeSeriesRx.current) storeRxAuto.txTypeSeriesRx = rf_txTypeSeriesRx.current.getVal();
      if (rf_dtRxAuto.current) storeRxAuto.dtRxAuto = rf_dtRxAuto.current.getVal();
      if (rf_txRxAuto.current) storeRxAuto.txRxAuto = rf_txRxAuto.current.getVal();
      if (rf_txNumberAnimals.current) storeRxAuto.txNumberAnimals = rf_txNumberAnimals.current.getVal();
      if (rf_txIdentification.current) storeRxAuto.txIdentification = rf_txIdentification.current.getVal();
      if (rf_txNumberRefills.current) storeRxAuto.txNumberRefills = rf_txNumberRefills.current.getVal();
      if (rf_boExceptional.current) storeRxAuto.boExceptional = rf_boExceptional.current.getVal();
      if (rf_txDaysValidity.current) storeRxAuto.txDaysValidity = rf_txDaysValidity.current.getVal();
      if (rf_txNotes.current) storeRxAuto.txNotes = rf_txNotes.current.getVal();

      if (storeRxAuto.boDataChanged === '0') {
        storeRxAuto.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
      }


      Promise.all([setStoreRxAuto(storeRxAuto)]).then((result) => {
        return;
      });

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.sendFormToStore', error)
    }
  }

  const formLoadCustom = () => {
    try {
      sendStoreToForm();

      if (!currentID) { //nuevo registro
        if (storeRxAuto.boFirstRender) {
          if (rf_dtRxAuto.current) rf_dtRxAuto.current.setValToday();
          if (rf_txNumberAnimals.current) rf_txNumberAnimals.current.setVal('1');
          if (rf_txDaysValidity.current) rf_txDaysValidity.current.setVal('30');
          //rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
          //rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
          if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
            rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
            rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
          }
          storeRxAuto.boFirstRender = false;
          setStoreRxAuto(storeRxAuto);
        }
      } else { // registro existente
      }

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.formLoadCustom', error)
    }
  }

  const formSaveCustom = () => {
    try {
      if (r_form.current.hasIncompleteRequired()) {
        return;
      }

      Promise.all([sendFormToStore()]).then((result) => {

        let filter = {};
        filter['txRxAuto'] = currentID;

        Promise.all([FxFetch.doJsonF(`rx_auto/cu`, filter, storeRxAuto)]).then((resultSave) => {
          if (resultSave[0]) {
            let txRxAuto = resultSave[0].theValue.split('|')[0];
            let itSeriesRx = resultSave[0].theValue.split('|')[1];
            storeRxAuto.boDataChanged = '0';
            storeRxAuto.txRxAuto = txRxAuto;
            storeRxAuto.itSeriesRx = itSeriesRx;

            r_form.current.resetChanged();
            setStoreRxAuto(storeRxAuto);
            setCurrentID(txRxAuto);
            rf_txRxAuto.current.setVal(txRxAuto);
            r_snack_save.current.setOpen(true);

            //navBack();
          }
        });
      });

    } catch (error) {
      FxLog.errorx('MxAnimalRxAutoForm.formSaveCustom', error)
    }
  }

  const formDeleteCustom = () => {
    setDlgDelete(true);
  }

  const doDelete = () => {
    //-----------------------------------------------------------------------
    setBackdrop(true);

    let url = `rx_auto/d`;
    Promise.all([FxFetch.doJsonF(url, null, storeRxAuto)]).then((result) => {
      FxLog.infox("MxAnimalRxAutoForm.doDelete", "deleting record...", result);
      if (result[0]) {
        FxLog.infox("MxAnimalRxAutoForm.doDelete", "Record was DELETED...responseData:", result[0]);
      } else {
        FxLog.infox("MxAnimalRxAutoForm.doDelete", "Record was DEACTIVATED...responseData:", result[0]);
      }
      r_snack_delete.current.setOpen(true);
    });
    //-----------------------------------------------------------------------
  }

  const canGoBack = () => {
    if (storeRxAuto.boDataChanged === '0') {
      storeRxAuto.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
    }
    if (storeRxAuto.boDataChanged === '0') {
      FxLog.infox("MxPurDocForm.canGoBack", "data was NOT changed!...");
      return true;
    } else {
      FxLog.infox("MxPurDocForm.canGoBack", "data was changed!...");
      setDlgExit(true);
      return false;
    }
  }

  const openFormItem = async (op, row) => {
    // actualiza el store
    sendFormToStore();

    if (row === null) {
      storeRxAuto.idx = null;
    } else {
      storeRxAuto.idx = row.original["idx"];
    }
    setStoreRxAuto(storeRxAuto);

    navTo("animal-rx-auto-form-item");
  }

  const exportToPresvet = () => {
    // exporta la rx a prescrivet
    let record = {};
    record['txRxAuto'] = rf_txRxAuto.current.getVal();
    record['itSeriesRx'] = rf_txTypeSeriesRx.current.getKey();
    Promise.all([FxFetch.doJsonX('/rx_auto/export_to_presvet', record)]).then((result) => {
      if (result[0]) {
        let rv = result[0].theValue;
        FxLog.infox('MxAnimalRxAutoForm.exportToPresvet', '...rv:', rv);
        if (rv === 'OK') {
          r_snack_success.current.setOpen(true);
        } else {
          if (rv==='rv_missing_employee_state') r_snack_error.current.setValAndOpen(trans('msg.missing_x_in_x_file', trans('field.state'), trans('field.employee')));
          if (rv==='rv_missing_employee_license') r_snack_error.current.setValAndOpen(trans('msg.missing_x_in_x_file', trans('field.license'), trans('field.employee')));
          if (rv==='rv_missing_employee_ic') r_snack_error.current.setValAndOpen(trans('msg.missing_x_in_x_file', trans('field.ic'), trans('field.employee')));
          if (rv==='rv_missing_client_ic') r_snack_error.current.setValAndOpen(trans('msg.missing_x_in_x_file', trans('field.ic'), trans('field.client')));
          if (rv==='rv_missing_client_code') r_snack_error.current.setValAndOpen(trans('msg.missing_x_in_x_file', trans('field.code'), trans('field.client')));
          if (rv==='rv_missing_ps_reference') r_snack_error.current.setValAndOpen(trans('msg.missing_x_in_x_file', `${trans('field.reference')} (Nro.Reg.Cimavet)`, trans('field.ps')));
          if (rv.startsWith('rv_prescrivet_|')) r_snack_error.current.setValAndOpen('Prescrivet: ' + rv.split('|')[1]);
          if (rv==='rv_error') r_snack_error.current.setValAndOpen(trans('msg.error'));
        }
      }
    });
  }

  const doPrintX = async (option) => {
    FxLog.infox('MxAnimalRxAutoForm.doPrintX', '...option', option);
    let boPreview = rf_print_preview.current.isChecked();
    let boCertSign = rf_print_cert_sign.current.isChecked();
    let inEmployee = storeRxAuto.inEmployee;
    let inClient = storeRxAuto.inClient;
    let inAnimal = storeRxAuto.inAnimal;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['txRxAuto'] = `${storeRxAuto.txRxAuto}`;
    filter['itSeriesRx'] = `${storeRxAuto.itSeriesRx}`;
    filter['docSize'] = 'A4';
    filter['nuCopies'] = (rf_print_copies.current ? rf_print_copies.current.getVal() : '1');
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('rx_auto/report');
    r_print.current.setPreview(boPreview);
    r_print.current.setCertificate(boCertSign ? certificate : {});
    localStorage.setItem('MxAnimalRxAutoForm.boPreview', boPreview ? '1' : '0');
    //----------------
    setDlgPrint(false);
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInEmployee(inEmployee),
      r_print.current.setInClient(inClient),
      r_print.current.setInAnimal(inAnimal),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  /* no deja modificar o eliminar si es factura, o un albarán con factura, o está en el stock */
  const isDisabled = () => { return Boolean(false) };

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.rx')}-${storeRxAuto.boAuto ? trans('field.automatic_0') : trans('field.manual')}`}
        xref={r_form}
        canGoBack={canGoBack}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {currentID && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => setDlgPrint(true)} tooltip={`${trans('field.print')} ${trans('field.paper_a4')}`} />}

            {/* botón de exportación presvet */}
            {FxInet.isDebug() && isSpain() && currentID && <CxIconButtonNew icon={<FaFileExport fontSize="22px" style={{margin: '1px 0 0 4px'}} />} classType="ibs_iconbutton_toolbar"
              tooltip={trans('field.export_to_x', 'Presvet')}
              onClick={exportToPresvet}
            />}

            {/* botón eliminar. Sólo eliminar lo que no es factura ni relacionado a factura. */}
            {currentID && !isDisabled() && isFullAccess() && <CxIconButton type="delete" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} />}

            {/* botón guardar. Incluso la factura puede guardar cambios para poder cambiar las notas. */}
            {!isDisabled() && isFullAccess() && <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} />}

          </div>
        }
      >
        <CxHidden xref={rf_inRxAuto} dbfield="n_rx_auto.inRxAuto" />
        <CxHidden xref={React.useRef(null)} dbfield="arItems" value={storeRxAuto.arItems || []} />

        <CxStatic label={`${storeClient.txClient} / ${storeClient.txAnimal}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />

        <CxSearcher xref={rf_txEmployee} label={trans('field.veterinarian')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_rx_auto.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
          required
        />

        <CxDate xref={rf_dtRxAuto} label={trans('field.date')} dbfield={"n_rx_auto.dtRxAuto"} required />
        <CxInput xref={rf_txRxAuto} label={trans('field.number')} dbfield={"n_rx_auto.txRxAuto"} disabled={true} placeholder={currentID ? '' : 'AUTO'} />

        <CxChooser xref={rf_txTypeSeriesRx} label={trans('field.series')}
          table="t_series_rx"
          col_id="itSeriesRx"
          col_tx="txTypeSeriesRx"
          disabled={Boolean(currentID)}
        />

        <CxInput xref={rf_txNumberAnimals} label={trans('field.rx_number_animals')} dbfield="n_rx_auto.txNumberAnimals" maxLength={10} defaultValue={1} required />
        <CxInput xref={rf_txIdentification} label={trans('field.rx_identification')} dbfield="n_rx_auto.txIdentification" />
        <CxInput xref={rf_txNumberRefills} label={trans('field.rx_number_refills')} dbfield="n_rx_auto.txNumberRefills" maxLength={10} />
        <CxState mode='checkbox' xref={rf_boExceptional} label={trans('field.rx_exceptional')} dbfield="n_rx_auto.boExceptional" />
        <CxInput xref={rf_txDaysValidity} label={trans('field.rx_days_validity')} dbfield="n_rx_auto.txDaysValidity" maxLength={5} />

        {/* PRODUCTOS Y SERVICIOS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {
          <CxTableMem
            id='doc_items'
            xref={rf_table_items}
            onOpenForm={openFormItem}
            hasButtonAdd={true /* Boolean(!storeRxAuto.boAuto) */}
            disabled={isDisabled()}
            pageSize={5}
            list={storeRxAuto.arItems || []}
            columns={[
              { id: "i", Header: "ID", accessor: "idx", show: false },
              { id: "0", Header: "ID", accessor: "inRxAuto", show: false },
              { id: "1", Header: trans('field.ps'), accessor: "txPs", width: '80%' },
              { id: "2", Header: trans('field.quantity'), accessor: row => FxTable.cellTo4r00(row["nuQuantity"]), width: '20%', align: 'right' },
            ]}
            label={trans('field.ps')}
            required
          />
        }

        <CxInput xref={rf_txNotes} label={trans('field.notes')} dbfield="n_rx_auto.txNotes" minRows={4} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => { setBackdrop(false) /* navBack(); */ }} />
      <CxSnackbar xref={r_snack_delete} severity="success" value={trans('msg.deleted')} onClose={() => { navBack(); }} />

      <CxDialog title={trans('msg.confirm_operation')} open={dlgExit} onCancel={() => setDlgExit(false)} onOk={() => navBack()} height='200px'>
        {trans('msg.ask_exit_without_saving')}
      </CxDialog>

      <CxDialog title={trans('msg.warning')} open={dlgDelete}
        onCancel={() => setDlgDelete(false)}
        onOk={() => { setDlgDelete(false); doDelete() }}
        height='200px'>
        {trans('msg.ask_delete')}
      </CxDialog>

      <CxDialog title={trans('msg.ask_print_x', 'field.rx')} open={dlgPrint} onCancel={() => setDlgPrint(false)} onOk={() => doPrintX()} height='290px'>
        <div style={{ width: 'var(--ibs_control_width_md)', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <CxNumber xref={rf_print_copies} label={trans('field.copies')}
            decimals={0}
            width='var(--ibs_control_width_md)'
            sumButton
          />
          <CxState mode='checkbox' xref={rf_print_preview}
            label={trans('field.preview')}
            width='var(--ibs_control_width_md)'
            value={localStorage.getItem('MxAnimalRxAutoForm.boPreview') || '1'}
          />
          <CxState mode='checkbox' xref={rf_print_cert_sign}
            label={trans('field.certificate_based_signature')}
            width='var(--ibs_control_width_md)'
            value={'0'}
            setCertificate={setCertificate}
            onClick={(value) => { if (value==='1') {
              if (!FxEdition.isInEdition('XXX_sig')) {
                rf_print_cert_sign.current.setValBoolean(false);
                r_dlg_upgrade.current.setOpen(true);
              } else {
                r_dlg_certificate.current.setOpen(true);
              }
            }; }}
          />
        </div>
      </CxDialog>

      <CxDlgCertificate xref={r_dlg_certificate} setCertificate={setCertificate} height='290px'
        onCancel={() => {
          rf_print_cert_sign.current.setValBoolean(false);
        }} 
      />

      <CxDlgAlert xref={r_dlg_alert} />
      <CxDlgUpgrade xref={r_dlg_upgrade} />
      <CxBackdrop open={backdrop} />
      <GxPrint xref={r_print} />

      <CxSnackbar xref={r_snack_error} severity="error" />
      <CxSnackbar xref={r_snack_success} severity="success" value={trans('msg.operation_successful')} />

    </div>
  );

}

export default MxAnimalRxAutoForm;
