import React from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import CxSnackbar from '../../cx/CxSnackbar';
import CxChooser from '../../cx/CxChooser';
import CxDate from '../../cx/CxDate';
import CxSearcher from '../../cx/CxSearcher';
import CxTime from '../../cx/CxTime';
//import CxHidden from '../../cx/CxHidden';
import { useNav } from '../../hx/useNav';
import CxSwitch from '../../cx/CxSwitch';
import { StoreWaiting, isApp } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import * as FxFetch from '../../fx/FxFetch';
import { useAccess } from '../../hx/useAccess';


const MxWaitingForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.waiting_room'));
  const [{ navBack }] = useNav();
  const r_form = React.useRef(null);

  const rf_txTypeStatusWaiting = React.useRef(null);
  const rf_issue = React.useRef(null);
  const rf_alert = React.useRef(null);

  const rf_boPresent = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_time = React.useRef(null);

  const rf_txClient = React.useRef(null);
  const rf_txAnimal = React.useRef(null);
  const rf_txEmployee = React.useRef(null);

  const [isDisabled, setIsDisabled] = React.useState(true);


  const [storeWaiting, setStoreWaiting] = useSessionStorage('StoreWaiting', StoreWaiting);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      setIsDisabled(false);

      rf_date.current.setValToday();
      rf_time.current.setValNow();
      rf_txTypeStatusWaiting.current.setKeyAndVal('t_status_waiting', 'txTypeStatusWaiting', 'itStatusWaiting', '000001.SRV');
      //rf_message.current.setFocus();
 
      if (storeWaiting.inClient) {
        let url = "clients/r";
        let record = {};
        record['idValue'] = storeWaiting.inClient;
        Promise.all([FxFetch.doJsonX(url, record)]).then((result) => {
          if (result[0]) {
            rf_txClient.current.setKey(result[0][0].inClient||'');
            rf_txClient.current.setVal(result[0][0].txClient||'');
          }
        });
        if (isApp('vett')) {
          // filtro de animales para el paciente seleccionado
          rf_txAnimal.current.setFilter(`inClient='${storeWaiting.inClient}'`);
        }
      }
      if (isApp('vett')) {
        if (storeWaiting.inAnimal) {
          rf_txAnimal.current.setKeyAndVal('n_animal', 'txAnimal', 'inAnimal', storeWaiting.inAnimal);
        }
      }
      if (storeWaiting.dtIssue) {
        rf_date.current.setVal(storeWaiting.dtIssue);
        rf_time.current.setVal('08:00');
      }
      
    } else {
      let isDisabled = data[0].dtInformed ? true: false;
      setIsDisabled(isDisabled);
      if (isApp('vett')) {
        // filtro de animales para el paciente seleccionado
        rf_txAnimal.current.setFilter(`inClient='${data[0].inClient}'`);
      }
    }
  }
  const formSave = async (lastSaveRv) => {
    storeWaiting.inWaiting = lastSaveRv.theValue;
    Promise.all([setStoreWaiting(storeWaiting)]).then((result) => {
      navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  const clientChanged = async (row) => {
    if (row !== null) {
      // inyecta filtros en el combo paciente
      let inClient = row.original.inClient;
      if (isApp('vett')) {
        rf_txAnimal.current.setFilter(`inClient='${inClient}'`);
        rf_txAnimal.current.clear();
      }
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.waiting_room')}`}
        xref={r_form}
        url="waiting"
        filterIdField='inWaiting' 
        filterIdValue={storeWaiting.inWaiting} 
        onFormLoad={formLoad}
        onFormSave={!isDisabled && isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete} 
      >

        <CxDate xref={rf_date} label={`${trans('field.date')}`} 
          dbfield={`n_waiting.dtIssue`} required disabled={isDisabled} />
        <CxTime xref={rf_time} label={`${trans('field.time')}`} 
          dbfield={`n_waiting.dtIssue`} required disabled={isDisabled} />

        <CxChooser xref={rf_txTypeStatusWaiting} label={trans('field.status')} 
          dbfield="t_status_waiting.txTypeStatusWaiting" dbfieldkey={`n_waiting.itStatusWaiting`}
          table="t_status_waiting"
          col_id="itStatusWaiting"
          col_tx="txTypeStatusWaiting"
          required
          disabled={isDisabled}
        />

        <CxSwitch xref={rf_boPresent} label={trans('field.present')} dbfield="n_waiting.boPresent" disabled={isDisabled} />
        <CxInput xref={rf_alert} label={trans('field.alert')} dbfield={`n_waiting.txAlert`} disabled={isDisabled} maxLength={200} />

        <CxSearcher xref={rf_txClient} label={trans('field.client')} 
          dbfield={`n_waiting.txClient`} dbfieldkey={`n_waiting.inClient`}
          onClear={() => {if (rf_txAnimal.current) rf_txAnimal.current.clear();}}
          onSelection={clientChanged}
          url="clients/searcher"
          col_id="inClient"
          col_tx="txClient"
          colorRefs={ [ 'table_cell_red|' + trans('field.debtor') ] }
          tooltip={`${trans('field.client')}`}
          columns={[
            { id: "0", Header: "ID", accessor: "inClient", show: false },
            { id: "1", Header: trans('field.client'), accessor: row => rf_txClient.current.getTable().highlightText(row["txClient"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
          disabled={isDisabled}
        />

        {isApp('vett') &&
          <CxChooser xref={rf_txAnimal} label={trans('field.patient')} 
            dbfield={`n_animal.txAnimal`} dbfieldkey={`n_waiting.inAnimal`}
            table="n_animal"
            col_id="inAnimal"
            col_tx="txAnimal"
            extra_filter_1="n_animal.boActive=1"
            canOpenDialog={() => {return rf_txClient.current.getKey() !== '';}}
            disabled={isDisabled}
          />
        }

        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          dbfield="n_employee.txEmployee" dbfieldkey="n_waiting.inEmployee"
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
        />

        <CxInput xref={rf_issue} label={trans('field.issue')} dbfield={`n_waiting.txIssue`} minRows={4} disabled={isDisabled} maxLength={255} />

      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />

    </div>
  );

}

export default MxWaitingForm;
