import React from 'react';
import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
//import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
import CxTableMem from '../../cx/CxTableMem';
import * as FxLog from '../../fx/FxLog';
import * as FxNum from '../../fx/FxNum';
import * as FxTable from '../../fx/FxTable';
import * as FxFetch from '../../fx/FxFetch';
import '../../../styles/main.css';
import CxBackdrop from '../../cx/CxBackdrop';
import CxSnackbar from '../../cx/CxSnackbar';
import CxChooser from '../../cx/CxChooser';
import CxDialog from '../../cx/CxDialog';
import CxDlgAlert from '../../cx/CxDlgAlert';
import CxChip from '../../cx/CxChip';
import { StorePurLst, StorePurDoc, getStoreValue, getStoreValueBo } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import GxForm from '../../gx/GxForm';
import GxPrint from '../../gx/GxPrint';
import CxIconButton from '../../cx/CxIconButton';
import * as MxPurDocFormH1 from './MxPurDocFormH1';
import { useAccess } from '../../hx/useAccess';
import { FaCoins } from "react-icons/fa";
import { MdReceipt } from "react-icons/md";


const MxPurDocForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.pos'));
  const [{ navTo, navBack }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  // eslint-disable-next-line no-unused-vars
  const [storePurLst, setStorePurLst] = useSessionStorage('StorePurLst', StorePurLst);
  const [storePurDoc, setStorePurDoc] = useSessionStorage('StorePurDoc', StorePurDoc);

  const r_form = React.useRef(null);
  let r_print = React.useRef(null);

  const r_snack_op_successful = React.useRef(null);
  const r_snack_op_failed = React.useRef(null);
  const r_snack_save = React.useRef(null);
  const r_snack_delete = React.useRef(null);
  const r_dlg_alert = React.useRef(null);

  const [docTypeName, setDocTypeName] = React.useState(null);

  const [backdrop, setBackdrop] = React.useState(false);

  const [dlgIssue, setDlgIssue] = React.useState(false);
  const [dlgDelete, setDlgDelete] = React.useState(false);
  const [dlgExit, setDlgExit] = React.useState(false);

  const rf_chip_rec = React.useRef(null);
  const rf_chip_balance = React.useRef(null);

  const rf_dtPurXXX = React.useRef(null);
  const rf_txPurXXX = React.useRef(null);

  const rf_txSupplier = React.useRef(null);
  const rf_txBranch = React.useRef(null);
  const rf_txEmployee = React.useRef(null);
  const rf_txContact = React.useRef(null);
  const rf_txReference = React.useRef(null);

  const rf_issue_dtPurXXX = React.useRef(null);
  const rf_issue_txPurXXX = React.useRef(null);

  const rf_table_items = React.useRef(null);
  const rf_table_payms = React.useRef(null);

  const rf_txNotes = React.useRef(null);

  const r_snack_required = React.useRef(null);


  React.useEffect(() => {
    try {
      if (storePurDoc.docType === 'o') {
        setDocTypeName(trans('field.order'));
      }
      if (storePurDoc.docType === 'i') {
        setDocTypeName(trans('field.invoice'));
      }
      if (storePurDoc.docType === 'r') {
        setDocTypeName(trans('field.receipt'));
      }

      formLoadCustom();

    } catch (error) {
      FxLog.errorx('MxPurDocForm.setup', error);
    }
    // eslint-disable-next-line
  }, []);

  const sendStoreToForm = () => {
    try {
      if (rf_dtPurXXX.current) rf_dtPurXXX.current.setVal(storePurDoc.dtPurXXX);
      if (rf_txPurXXX.current) rf_txPurXXX.current.setVal(storePurDoc.txPurXXX);

      if (rf_txSupplier.current) rf_txSupplier.current.setKey(storePurDoc.inSupplier);
      if (rf_txSupplier.current) rf_txSupplier.current.setVal(storePurDoc.txSupplier);
      if (rf_txBranch.current) rf_txBranch.current.setKey(storePurDoc.inBranch);
      if (rf_txBranch.current) rf_txBranch.current.setVal(storePurDoc.txBranch);
      if (rf_txEmployee.current) rf_txEmployee.current.setKey(storePurDoc.inEmployee);
      if (rf_txEmployee.current) rf_txEmployee.current.setVal(storePurDoc.txEmployee);
      if (rf_txContact.current) rf_txContact.current.setVal(storePurDoc.txContact);
      if (rf_txReference.current) rf_txReference.current.setVal(storePurDoc.txReference);

      if (rf_txNotes.current) rf_txNotes.current.setVal(storePurDoc.txNotes);

    } catch (error) {
      FxLog.errorx('MxPurDocForm.sendStoreToForm', error)
    }
  }

  const sendFormToStore = () => {
    try {
      if (rf_dtPurXXX.current) storePurDoc.dtPurXXX = rf_dtPurXXX.current.getVal();
      if (rf_txPurXXX.current) storePurDoc.txPurXXX = rf_txPurXXX.current.getVal();

      if (rf_txSupplier.current) storePurDoc.inSupplier = rf_txSupplier.current.getKey();
      if (rf_txSupplier.current) storePurDoc.txSupplier = rf_txSupplier.current.getVal();
      if (rf_txBranch.current) storePurDoc.inBranch = rf_txBranch.current.getKey();
      if (rf_txBranch.current) storePurDoc.txBranch = rf_txBranch.current.getVal();
      if (rf_txEmployee.current) storePurDoc.inEmployee = rf_txEmployee.current.getKey();
      if (rf_txEmployee.current) storePurDoc.txEmployee = rf_txEmployee.current.getVal();
      if (rf_txContact.current) storePurDoc.txContact = rf_txContact.current.getVal();
      if (rf_txReference.current) storePurDoc.txReference = rf_txReference.current.getVal();

      if (rf_txNotes.current) storePurDoc.txNotes = rf_txNotes.current.getVal();

      if (storePurDoc.boDataChanged === '0') {
        storePurDoc.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
      }

      setStorePurDoc(storePurDoc);

    } catch (error) {
      FxLog.errorx('MxPurDocForm.sendFormToStore', error)
    }
  }

  const formLoadCustom = () => {
    try {
      sendStoreToForm();

      if (!storePurDoc.inPurXXX) { //nuevo registro
        if (storePurDoc.boFirstRender) {
          if (rf_dtPurXXX.current) rf_dtPurXXX.current.setValToday();
          rf_txBranch.current.setKey(getStoreValue('StoreSession', 'inBranch'));
          rf_txBranch.current.setVal(getStoreValue('StoreSession', 'txBranch'));
          if (getStoreValueBo('StoreSession', 'boAutoSelEmployee')) {
            rf_txEmployee.current.setKey(getStoreValue('StoreSession', 'inEmployee'));
            rf_txEmployee.current.setVal(getStoreValue('StoreSession', 'txEmployee'));
          }
          storePurDoc.boFirstRender = false;
          setStorePurDoc(storePurDoc);
        }
      } else { // registro existente
      }

      setBalance();

    } catch (error) {
      FxLog.errorx('MxPurDocForm.formLoadCustom', error)
    }
  }

  const formSaveCustom = () => {
    try {
      if (r_form.current.hasIncompleteRequired()) {
        return;
      }

      setBackdrop(true);
      sendFormToStore();

      Promise.all([FxFetch.doJsonF(`pur/doc/cu`, null, storePurDoc)]).then((resultSave) => {
        if (resultSave[0]) {
          // limpieza y recarga
          r_form.current.resetChanged();
          storePurDoc.boDataChanged = '0';
          storePurDoc.inPurXXX = resultSave[0].inPurXXX;
          storePurDoc.arItems = [];
          storePurDoc.arPayms = [];
          Promise.all([MxPurDocFormH1.load(storePurLst, storePurDoc)]).then((result) => {
            setStorePurDoc(storePurDoc);
            rf_txPurXXX.current.setVal(resultSave[0].txPurXXX);
            r_snack_save.current.setOpen(true);
          });
        }
      });

    } catch (error) {
      FxLog.errorx('MxPurDocForm.formSaveCustom', error)
    }
  }

  const formDeleteCustom = () => {
    setDlgDelete(true);
  }

  const doDelete = () => {
    //-----------------------------------------------------------------------
    setBackdrop(true);

    let url = `pur/doc/${storePurDoc.docType}/d`;
    Promise.all([FxFetch.doJsonF(url, null, storePurDoc)]).then((result) => {
      FxLog.infox("MxPurDocForm.doDelete", "deleting record...", result);
      if (result[0]) {
        FxLog.infox("MxPurDocForm.doDelete", "Record was DELETED...responseData:", result[0]);
      } else {
        FxLog.infox("MxPurDocForm.doDelete", "Record was DEACTIVATED...responseData:", result[0]);
      }
      r_snack_delete.current.setOpen(true);
    });
    //-----------------------------------------------------------------------
  }

  const setBalance = () => {
    try {
      let nuTotalDiscount = 0;
      let nuTotalTaxable = 0;
      let nuTotalTax = 0;
      let nuTotalAmount = 0;
      let nuTotalSurcharge = 0;
      let nuTotalPayment = 0;

      if (storePurDoc.arItems) {
        storePurDoc.arItems.forEach(function (item, index) {
          nuTotalDiscount = nuTotalDiscount + parseFloat(item.nuDiscount);
          nuTotalTaxable = nuTotalTaxable + parseFloat(item.nuTaxable);
          nuTotalTax = nuTotalTax + parseFloat(item.nuTax);
          nuTotalAmount = nuTotalAmount + parseFloat(item.nuAmount);
          nuTotalSurcharge = nuTotalSurcharge + parseFloat(item.nuSurcharge);
        })
      }
      if (storePurDoc.arPayms) {
        storePurDoc.arPayms.forEach(function (paym, index) {
          nuTotalPayment = nuTotalPayment + parseFloat(paym.nuAmount);
        })
      }

      storePurDoc.nuTotalDiscount = nuTotalDiscount.toString();
      storePurDoc.nuTotalTaxable = nuTotalTaxable.toString();
      storePurDoc.nuTotalTax = nuTotalTax.toString();
      
      // 2023-01-12 - daq: tratamos los valores que se utilizan para el balance
      if (storePurDoc.docType === 'r') {
        nuTotalAmount = storePurDoc.nuTotalAmount;
        nuTotalSurcharge = storePurDoc.nuTotalSurcharge;
        nuTotalPayment = storePurDoc.nuTotalPayment;
      } else {
        storePurDoc.nuTotalAmount = FxNum.to2r(nuTotalAmount).toString();
        storePurDoc.nuTotalSurcharge = FxNum.to2r(nuTotalSurcharge).toString();
        storePurDoc.nuTotalPayment = FxNum.to2r(nuTotalPayment).toString();
      }

      let balance = FxNum.to2r(nuTotalPayment) - (FxNum.to2r(nuTotalAmount) + FxNum.to2r(nuTotalSurcharge));
      storePurDoc.balance = balance;

      
      setStorePurDoc(storePurDoc);
      
      let total_label;
      if (storePurDoc.docType === 'o') {
        if (nuTotalSurcharge===0) {
          total_label = `${trans('field.total')}: ${FxTable.cellTo2r00(nuTotalAmount)}`;
          if (storePurDoc.docType !== 'r') rf_table_items.current.setStatusbarText(null);
        } else {
          total_label = `${trans('field.total')}: ${FxTable.cellTo2r00(nuTotalAmount)} + ${trans('field.surcharge')}: ${FxTable.cellTo2r00(nuTotalSurcharge)} = ${FxTable.cellTo2r00(Math.abs(balance))}`;
          if (storePurDoc.docType !== 'r') rf_table_items.current.setStatusbarText(`${trans('field.surcharge')}: ${FxTable.cellTo2r00(nuTotalSurcharge)}`);
        }
        rf_chip_balance.current.setLabel(`${trans('field.total')}: ${FxTable.cellTo2r00(Math.abs(balance))}`);
      } else {
        if (nuTotalSurcharge===0) {
          total_label = `${trans('field.total')}: ${FxTable.cellTo2r00(nuTotalAmount)} - ${trans('field.payment')}: ${FxTable.cellTo2r00(nuTotalPayment)} = ${FxTable.cellTo2r00(Math.abs(balance))}`;
          if (storePurDoc.docType !== 'r') rf_table_items.current.setStatusbarText(null);
        } else {
          total_label = `${trans('field.total')}: ${FxTable.cellTo2r00(nuTotalAmount)} + ${trans('field.surcharge')}: ${FxTable.cellTo2r00(nuTotalSurcharge)} - ${trans('field.payment')}: ${FxTable.cellTo2r00(nuTotalPayment)} = ${FxTable.cellTo2r00(Math.abs(balance))}`;
          if (storePurDoc.docType !== 'r') rf_table_items.current.setStatusbarText(`${trans('field.surcharge')}: ${FxTable.cellTo2r00(nuTotalSurcharge)}`);
        }
        rf_chip_balance.current.setWarn(balance < 0);
        rf_chip_balance.current.setLabel(`${trans('field.balance')}: ${FxTable.cellTo2r00(balance)}`);
      }
      rf_chip_balance.current.setTooltip(total_label);

    } catch (error) {
      FxLog.errorx('MxPurDocForm.setBalance', error)
    }
  }

  const openFormItem = async (op, row) => {
    // actualiza el store
    sendFormToStore();

    if (row === null) {
      storePurLst.table_item_idx = null;
    } else {
      storePurLst.table_item_idx = row.original["idx"];
    }
    setStorePurLst(storePurLst);
    setStorePurDoc(storePurDoc);

    navTo("pur-doc-form-item");
  }

  const openFormPaym = async (op, row) => {
    // actualiza el store
    sendFormToStore();

    if (row === null) {
      storePurLst.table_paym_idx = null;
    } else {
      storePurLst.table_paym_idx = row.original["idx"];
    }
    setStorePurLst(storePurLst);
    setStorePurDoc(storePurDoc);

    navTo("pur-doc-form-paym");
  }

  const supplierCleared = () => {
    storePurDoc.arRates = '';
    setStorePurDoc(storePurDoc);
  }

  const supplierChanged = (row) => {
    if (row !== null) {
      // inyecta filtros en el selector paciente
      //let inSupplier = row.original.inSupplier;
    }
  }

  const doPrintX = async (option) => {
    let inSupplier = storePurDoc.inSupplier;
    let filter = {};
    filter['reportFormat'] = `PDF`;
    filter['inSupplier'] = `${storePurDoc.inSupplier}`;
    filter['inPurXXX'] = `${storePurDoc.inPurXXX}`;
    filter['docType'] = `${storePurDoc.docType}`;
    filter['docSize'] = 'A4';
    let record = {};
    r_print.current.setScale(1.5);
    r_print.current.setUrl('purDoc/report');
    r_print.current.setPreview(true);
    //----------------
    Promise.all([
      r_print.current.setFilter(filter),
      r_print.current.setRecord(record),
      r_print.current.setInSupplier(inSupplier),
    ]).then((result) => {
      r_print.current.doPrint();
    });
  }

  const issueDoc = () => {
    try {
      if (rf_issue_dtPurXXX.current.isEmpty()) {
        r_snack_required.current.setVal(trans('field.required') + ": " + rf_issue_dtPurXXX.current.getLabel());
        r_snack_required.current.setOpen(true);
        return;
      }
      if (rf_issue_txPurXXX.current.isEmpty()) {
        r_snack_required.current.setVal(trans('field.required') + ": " + rf_issue_txPurXXX.current.getLabel());
        r_snack_required.current.setOpen(true);
        return;
      }
      if (storePurDoc.docType === 'i' && !storePurDoc.inPurReceipt) {
        let record = {};
        record['inPurInvoice'] = storePurDoc.inPurXXX;
        record['dtPurReceipt'] = rf_issue_dtPurXXX.current.getVal();
        record['txPurReceipt'] = rf_issue_txPurXXX.current.getVal();
        Promise.all([FxFetch.doJsonX(`/pur/doc/issue_receipt_from_invoice`, record)]).then((result) => {
          storePurDoc.inPurReceipt = result[0].theValue;
          setStorePurDoc(storePurDoc);
          forceUIUpdate();
        });
      }
      if (storePurDoc.docType === 'o') {
        let record = {};
        record['inPurOrder'] = storePurDoc.inPurXXX;
        record['dtPurInvoice'] = rf_issue_dtPurXXX.current.getVal();
        record['txPurInvoice'] = rf_issue_txPurXXX.current.getVal();
        Promise.all([FxFetch.doJsonX(`/pur/doc/issue_invoice_from_order`, record)]).then((result) => {
        });
      }
      setDlgIssue(false);
      r_snack_op_successful.current.setOpen(true);

    } catch (error) {
      FxLog.errorx("MxPurDocForm.issueDoc", error);
    }
  }

  const doStockUpdate = () => {
    try {
      if (storePurDoc.docType === 'i' && !storePurDoc.inPurReceipt) {
        let record = {};
        record['inPurInvoice'] = storePurDoc.inPurXXX;
        if (storePurDoc.nuStatus === '0') {
          storePurDoc.nuStatus = '1';
        } else {
          storePurDoc.nuStatus = '0';
        }
        record['nuStatus'] = storePurDoc.nuStatus;
        setStorePurDoc(storePurDoc);
        forceUIUpdate();
        Promise.all([FxFetch.doJsonX(`/pur/doc/stock_update`, record)]).then((result) => {
        });
      }
      r_snack_op_successful.current.setOpen(true);

    } catch (error) {
      FxLog.errorx("MxPurDocForm.doStockUpdate", error);
    }
  }

  const logStatus = () => {
    // eslint-disable-next-line no-unused-vars
    let status = {
      "storePurDoc.inPurXXX": storePurDoc.inPurXXX,
      "storePurDoc.nuStatus": storePurDoc.nuStatus,
      "storePurDoc.inPurReceipt": storePurDoc.inPurReceipt,
    }
  }

  const canGoBack = () => {
    if (storePurDoc.boDataChanged === '0') {
      storePurDoc.boDataChanged = (r_form.current.wasChanged() ? '1' : '0');
    }
    if (storePurDoc.boDataChanged === '0') {
      FxLog.infox("MxPurDocForm.canGoBack", "data was NOT changed!...");
      return true;
    } else {
      FxLog.infox("MxPurDocForm.canGoBack", "data was changed!...");
      setDlgExit(true);
      return false;
    }
  }


  /* no deja modificar o eliminar si es factura, o un albarán con factura, o está en el stock */
  const isDisabled = () => { return Boolean(storePurDoc.docType !== 'r' && ((storePurDoc.docType === 'i' && storePurDoc.inPurReceipt) || Boolean(storePurDoc.nuStatus==='0')) ? true : false) };

  return (
    <div>
      {logStatus()}
      <GxForm
        xref={r_form}
        moduleName={`${trans('field.purchases')}: ${docTypeName}`}
        canGoBack={canGoBack}
        toolbarBackground={storePurLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>

            {/* botón de impresión */}
            {(storePurDoc.docType === 'o' || storePurDoc.docType === 'i' || storePurDoc.docType === 'r')
              && storePurDoc.inPurXXX
              && <CxIconButton type="print" classType="ibs_iconbutton_toolbar" onClick={() => doPrintX()} tooltip={trans('field.print')} />}

            {/* botón de añadir a stock */}
            {(storePurDoc.docType === 'i') && storePurDoc.inPurXXX && !storePurDoc.inPurReceipt && !isDisabled() 
              && <CxIconButton type="add_to" classType="ibs_iconbutton_toolbar" 
                 onClick={() => doStockUpdate()} tooltip={trans('field.add_to_x','field.stock')} />}
            {/* botón de quitar de stock */}
            {(storePurDoc.docType === 'i') && storePurDoc.inPurXXX && !storePurDoc.inPurReceipt && isDisabled() 
              && <CxIconButton type="remove_from" classType="ibs_iconbutton_toolbar" 
                 onClick={() => doStockUpdate()} tooltip={trans('field.remove_from_x','field.stock')} />}

            {/* botón de generación de albarán o factura */}
            {(storePurDoc.docType === 'o')
              && storePurDoc.inPurXXX && isFullAccess()
              && <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={() => setDlgIssue(true)}
                tooltip={trans('field.issue_x','field.invoice')} />}
            {(storePurDoc.docType === 'i')
              && !storePurDoc.inPurReceipt && storePurDoc.inPurXXX && isFullAccess() && Boolean(storePurDoc.nuStatus==='0') 
              && <CxIconButton type="issue" classType="ibs_iconbutton_toolbar" onClick={() => setDlgIssue(true)}
                tooltip={trans('field.issue_x','field.receipt')} />}

            {/* botón eliminar. Sólo eliminar lo que no es factura ni relacionado a factura. */}
            {storePurDoc.inPurXXX && !isDisabled() && isFullAccess() 
              && <CxIconButton type="delete" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} tooltip={trans('field.delete')} />}

            {/* botón guardar. Incluso la factura puede guardar cambios para poder cambiar las notas. */}
            {isFullAccess() 
              && <CxIconButton type="save" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.save')} />}

          </div>
        }
      >

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', gap: '5px' }}>
          {storePurDoc.docType === 'i' && storePurDoc.inPurReceipt && <CxChip xref={rf_chip_rec} icon={<MdReceipt size={20} color={"var(--ibs_color_toggle_green)"} />} label={trans('field.receipted')} />}
          <CxChip xref={rf_chip_balance} icon={<FaCoins size={18} color={"var(--ibs_color_cy_yellow)"} />} />
        </div>

        {/* DATOS DE DOCUMENTO */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        <CxDate xref={rf_dtPurXXX} label={trans('field.date')} required disabled={isDisabled()} />
        <CxInput xref={rf_txPurXXX} label={trans('field.doc')}
          maxLength="16"
          required
          disabled={isDisabled()}
        />

        {/* DATOS DE PERSONAS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        <CxSearcher xref={rf_txSupplier}
          label={trans('field.supplier')}
          onClear={supplierCleared}
          onSelection={supplierChanged}
          url="suppliers"
          col_id="inSupplier"
          col_tx="txSupplier"
          colorRefs={ [ 'table_cell_red|' + trans('field.debtor') ] }
          columns={[
            { id: "0", Header: "ID", accessor: "inSupplier", show: false },
            { id: "1", Header: trans('field.supplier'), accessor: row => rf_txSupplier.current.getTable().highlightText(row["txSupplier"]), width: '100%' },
            { id: "R", Header: "balance", accessor: row => Boolean((row["theBalance"]) < 0 ? true : false), show: false },
          ]}
          disabled={isDisabled()}
          required
        />

        <CxChooser xref={rf_txBranch}
          label={trans('field.branch')}
          id="n_branch"
          table="n_branch"
          col_id="inBranch"
          col_tx="txBranch"
          extra_filter_1={"boActive='1'"}
          disabled={isDisabled()}
          required
        />
        <CxSearcher xref={rf_txEmployee} label={trans('field.employee')}
          url="employees" col_id="inEmployee" col_tx="txEmployee"
          columns={[
            { id: "0", Header: "ID", accessor: "inEmployee", show: false },
            { id: "1", Header: trans('field.employee'), accessor: row => rf_txEmployee.current.getTable().highlightText(row["txEmployee"]), width: '100%' },
          ]}
          disabled={isDisabled()}
        />

        <CxInput xref={rf_txContact} label={trans('field.contact')} maxLength={100} disabled={isDisabled()} />
        <CxInput xref={rf_txReference} label={trans('field.reference')} maxLength={50} disabled={isDisabled()} />


        {/* PRODUCTOS Y SERVICIOS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {(storePurDoc.docType === 'i' || storePurDoc.docType === 'o') &&
          <CxTableMem
            id='doc_items'
            xref={rf_table_items}
            onOpenForm={openFormItem}
            hasButtonAdd={isFullAccess()}
            disabled={Boolean(isDisabled() || storePurDoc.inPurReceipt)}
            pageSize={5}
            list={storePurDoc.arItems || []}
            columns={[
              { id: "i", Header: "ID", accessor: "idx", show: false },
              { id: "0", Header: "ID", accessor: `${storePurLst.table_item_idField}`, show: false },
              { id: "1", Header: trans('field.ps'), accessor: "txPs", width: '60%' },
              { id: "2", Header: trans('field.quantity'), accessor: row => FxTable.cellTo2r00(row["nuQuantity"]), width: '20%', align: 'right' },
              { id: "3", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["nuAmount"]), width: '20%', align: 'right' },
              /*{ id: "4", Header: trans('field.surcharge'), accessor: row => FxTable.cellTo2r00(row["nuSurcharge"]), width: '20%', align: 'right' },*/
            ]}
            label={trans('field.ps')}
            required
          />
        }

        {/* PAGOS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        {storePurDoc.docType === 'i' &&
          <CxTableMem
            id='doc_payms'
            xref={rf_table_payms}
            onOpenForm={openFormPaym}
            hasButtonAdd={isFullAccess()}
            disabled={false}
            pageSize={5}
            list={storePurDoc.arPayms || []}
            columns={[
              { id: "i", Header: "ID", accessor: "idx", show: false },
              { id: "0", Header: "ID", accessor: "inPurPayment", show: false },
              { id: "1", Header: trans('field.date'), accessor: row => FxTable.cellToLocalDate(row["dtPurPayment"]), width: '30%' },
              { id: "2", Header: trans('field.payment'), accessor: "txTypePayment", width: '50%' },
              { id: "3", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["nuAmount"]), width: '20%', align: 'right' },
            ]}
          />
        }

        {/* NOTAS */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
        <CxInput xref={rf_txNotes} label={trans('field.notes')} minRows={2} disabled={isDisabled()} />

      </GxForm>

      <CxDlgAlert xref={r_dlg_alert} />

      <CxSnackbar xref={r_snack_op_successful} severity="success" value={trans('msg.operation_successful')} />
      <CxSnackbar xref={r_snack_op_failed} severity="error" value={trans('msg.operation_failed')} />
      <CxSnackbar xref={r_snack_save} severity="success" value={trans('msg.saved')} onClose={() => { setBackdrop(false) /* navBack(); */ }} />
      <CxSnackbar xref={r_snack_delete} severity="success" value={trans('msg.deleted')} onClose={() => { navBack(); }} />

      <CxDialog
        title={trans('field.issue_x', storePurDoc.docType === 'o' ? trans('field.invoice') : trans('field.receipt'))}
        open={dlgIssue}
        onCancel={() => setDlgIssue(false)} 
        onOk={() => issueDoc()}
        height='300px'>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
          <CxDate xref={rf_issue_dtPurXXX} label={trans('field.date')} width='var(--ibs_control_width_md)' today required />
          <CxInput xref={rf_issue_txPurXXX} label={trans('field.doc')} width='var(--ibs_control_width_md)' required />
        </div>
      </CxDialog>

      <CxDialog title={trans('msg.confirm_operation')} open={dlgExit} onCancel={() => setDlgExit(false)} onOk={() => navBack()} height='200px'>
        {trans('msg.ask_exit_without_saving')}
      </CxDialog>

      <CxDialog title={trans('msg.warning')} open={dlgDelete}
        onCancel={() => setDlgDelete(false)}
        onOk={() => { setDlgDelete(false); doDelete() }}
        height='200px'>
        {trans('msg.ask_delete')}
      </CxDialog>

      <GxPrint xref={r_print}/>
      <CxBackdrop open={backdrop} />
      <CxSnackbar xref={r_snack_required} severity="error" />

    </div>
  );

}

export default MxPurDocForm;
