// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

export const StoreSession = {
  /** LOGIN -> */
  dtFetch: '', // YYYY-MM-DD HH:MM - fecha y hora de petición.
  dtLogin: '', // YYYY-MM-DD - fecha de trabajo.
  inLogin: '', // 10 caracteres alfanuméricos generados por el servidor.
  txDevice: '', // desktop/mobile
  txLocale: '', // en_US, es_ES, etc.
  txDecimalSeparator: '', // punto o coma.
  txDateFormat: '', // MM-dd-yyyy, dd-MM-yyyy
  txIdi: '',
  txApp: '',
  txAppType: '',
  txEdition: '',
  inSecGroup: '',
  inUser: '',
  txUser: '',
  inClientCP: '', // Si el logado es un cliente en el portal del cliente.
  txCompany: '',
  inBranch: '',
  txBranch: '',
  txBranchId: '',
  txBranchRgb: '',
  boMainOffice: '',
  itCash: '',
  txTypeCash: '',
  inEmployee: '',
  txEmployee: '',
  txLicense: '',

  /** BRANCH -> */
  txDefaultCity: '',
  txDefaultState: '',
  txDefaultPostalCode: '',
  txDefaultCountry: '',
  nuIdleMinsUntilLock: '',
  nuBarcodeType: '',
  nuDecimals: '',
  boDecimalsInDocs: '',
  txBranchHeaderInDocs: '',
  txBranchHeader: '',
  txBranchFooter: '',
  txPin: '',
  txDataProtection: '',
  boAutoNumFiles: '',
  boAutoFillTables: '',
  boToggleRowColor: '',
  boExactSearches: '',
  boShowBranchDataOnly: '',
  boShowBranchPsOnly: '',
  boElectronicPurse: '',
  boSynchronization: '',
  boConfirmExit: '',
  boUseInvoices: '',
  boUseReceipts: '',
  boUsePurInvoices: '',
  boUsePurReceipts: '',
  boShowAllPurPs: '',
  boAnnualNumeration: '',
  boAutoSelEmployee: '',
  boUserLog: '',
  boLockOldFiles: '',
  boUseUcaseForNames: '',
  boRequestPin: '',
  boPreventDuplicateCli: '',
  boPreventDuplicatePss: '',
  nuSalesTarget: '',
  boLotControl: '',
  boWTaxByDefault: '',
  nuWTaxPerc: '',
  nuOverPricePerc: '',
  boShowClientA4: '',
  boShowClientA5: '',
  boShowClientA8: '',
  boFirstTabIsClient: '',
  boDefaultIsReceipt: '',
  boConfirmationEnabled: '',
  boRememberSubmodules: '',
  boCashControlReq: '',
  boCashUpDateMatch: '',
  boUseDocsWord: '',
  boEnableOTF: '',
  boCustomBlobsAutoDl: '',
  itEmailForComm: '',
  inUserToShowCommDlg: '',
  txPcIdRemSender: '',
  boHiddenReports: '',
  boInvoicingUpdateDelete: '',

  // OPTI+VETT
  boSpecialInvoice: '',
  boSpecialReceipt: '',
  boCashUpPendingPs: '',
  boAutoCreateFq: '',
  boAutoCreateComm: '',

  // SOLO OPTI
  // SOLO VETT
  boAutoCreateWa: '',
  boAutoCreateRx: '',
  boAutoCreateRxForce: '',
  boAutoCreateEu: '',
  boAutoCreateSt: '',
  boAutoCreateMp: '',
  boAnimalAlertMsg: '',
  boRememberCnsSpc: '',
};

// estadísticas de empresa para establecer límites a ediciones
export const StoreCyStats = {
  nuSalesThisMonth: '', // para limitar la ST a 100 albaranes por mes.
  nuSalesLastMonth: '', // para ralentizar inferiores a PM si hicieron más de 500 albaranes el mes anterior.
};

export const StoreCash = {
  dtCashItemFrom: '',
  dtCashItemTo: '',
  itCash: '',
  inClient: '',
  txClient: '',
  txTypeCash: '',
  inCashItem: '',
  arPayments: '',
};

// para facturación masiva
export const StoreMassBilling = {
  dtInvoiceFrom: '',
  dtInvoiceTo: '',
  inClient: '',
  txClient: '',
  inEmployee: '',
  txEmployee: '',
  inBranch: '',
  txBranch: '',
  txInvoice: '',
  nuTotalFinalFrom: '',
  nuTotalFinalTo: '',
  nuTotalTaxFrom: '',
  nuTotalTaxTo: '',
  boPaid: '',
  arPayments: '',
  arSeries: '',
  arCash: '',
};

// para integración albaranes de proveedor
export const StoreIntegrationPurInvoices = {
  strSupplierName: '',
  dtFrom: '',
  boCreatePss: '',
};

export const StoreHistory = {
  historyType: '',
  inBranch: '',
  txBranch: '',
  dtFrom: '',
  dtTo: '',
  txPs: '',
  itPsGroup: '',
  txTypePsGroup: '',
  itPsSubgroup: '',
  txTypePsSubgroup: '',
  inSupplier: '',
  txSupplier: '',
  inClient: '',
  txClient: '',
  inAnimal: '',
  txAnimal: '',
};

// para fichas de cliente y animal
export const StoreClient = {
  inClient: '',
  txClient: '',
  inAnimal: '',
  txAnimal: '',
  inClientIncidence: '',
  inClientDoc: '',
  inImage: '',
  inMultimedia: '',
};
export const StoreAnimalWeight = { inAnimalWeight: '' };
export const StoreGrooming = { inGrooming: '' };
export const StoreCns = { inCns: '' };
export const StoreVacc = { inVacc: '' };
export const StoreRxGlas = { inRxGlas: '' };
export const StoreRxClen = { inRxClen: '' };
export const StoreRepl = { inRepl: '' };
export const StoreLabOrder = { inLabOrder: '', inRxGlas: '' };

// para historial de actividad
export const StoreClientActivityOptiLst = {
  listType: '',
  listTypeBackground: '',
  // Cargados en changeType():
  inClient: '',
  txClient: '',
  table: '', // 'n_repl', 'n_rx_glas', 'n_rx_clen'
  table_idField: '', // 'inRepl', 'inRxGlas', 'inRxClen'
};
export const StoreClientActivityVettLst = {
  listType: '',
  listTypeBackground: '',
  // Cargados en changeType():
  inClient: '',
  txClient: '',
  inAnimal: '',
  txAnimal: '',
  table: '', // 'n_cns', 'n_vacc', 'n_grooming'
  table_idField: '', // 'inCns', 'inVacc', 'inGrooming'
};

export const StoreRxAuto = {
  boFirstRender: '', // flag que indica si es la primera vez que abre el doc, y no es navegación interna.
  boDataChanged: '', // flag que indica si el usuario cambió datos del documento
  boAuto: '', // flag que indica si la receta es automática o manual
  //--------------------------
  inRxAuto: '', 
  txRxAuto: '', 
  dtRxAuto: '', 
  inClient: '', 
  txClient: '', 
  inAnimal: '', 
  txAnimal: '', 
  txNumberAnimals: '', 
  txIdentification: '', 
  inEmployee: '', 
  txEmployee: '', 
  itSeriesRx: '', 
  txTypeSeriesRx: '', 
  txNumberRefills: '', 
  boExceptional: '', 
  txDaysValidity: '', 
  txNotes: '', 
  inInvoice: '', 
  txInvoice: '', 
  dtInvoice: '', 
  //--------------------------
  arItems: '',
  txItemsDeleted: '',
};
export const StoreRxAutoPs = { 
  itRxAuto: '', 
  inPs: '', 
};

export const StorePlan = { inPlan: '' };
export const StorePlanPs = { 
  irPlanPs: '', 
  inPlan: '', 
  inPs: '', 
};

export const StoreEmployee = { 
  inEmployee: '',
  inCommission: '',
};

export const StoreReferrer = { inReferrer: '' };

export const StoreSupplier = { inSupplier: '' };

export const StoreLab = { inLab: '' };

export const StoreCalendarView = {
  selectedView: '',
  selectedDate: '',
  itCalendarCol: '', // para cuando el calendario está en vista simple (sin columnas)
  txTypeCalendarCol: '', // para cuando el calendario está en vista simple (sin columnas)
  boSingleCalendar: '',
  txWeekDays: '',
  txStartHour: '',
  txWorkTime1From: '',
  txWorkTime1To: '',
  txWorkTime2From: '',
  txWorkTime2To: '',
  nuIntervalMins: '',
};
export const StoreCalendarEvent = {
  inCalendar: '',
  dtFrom: '',
  itCalendarCol: '', // para cuando el calendario está en vista de recursos (con columnas)
  txTypeCalendarCol: '', // para cuando el calendario está en vista de recursos (con columnas)
};

// para ventas
export const StoreSlsLst = {
  listType: '',
  listTypeBackground: '',
  // Cargados en MxSlsDocList.changeDocType():
  table: '', // 'n_quotation', 'n_invoice', 'n_receipt'
  table_idField: '', // 'inQuotation', 'inInvoice', 'inReceipt'
  table_txField: '', // 'txQuotation', 'txInvoice', 'txReceipt'
  table_dtField: '', // 'dtQuotation', 'dtInvoice', 'dtReceipt'
  table_item: '', // 'n_quotation_item', 'n_invoice_item', 'n_receipt_item'
  table_item_idField: '', // 'inQuotationItem', 'inInvoiceItem'
  table_paym_idField: '', // 'inPayment'
  table_item_idx: '', // el índice dentro del array
  table_paym_idx: '', // el índice dentro del array
};
export const StoreSlsDoc = {
  // Cargados en MxSlsDocList.openForm():
  boFirstRender: '', // flag que indica si es la primera vez que abre el doc, y no es navegación interna.
  boDataChanged: '', // flag que indica si el usuario cambió datos del documento
  boUpdateDelete: '', // flag que indica si se permite operaciones especiales (ej: eliminar facturas)
  //--------------------------
  docType: 'i', // i-albarán, r-factura, q-presupuesto
  inXXX: '', // identificador del documento
  txXXX: '', // número de documento
  dtXXX: '', // fecha y hora del documento
  itSeries: '', // identificador de la serie
  txTypeSeries: '', // nombre de la serie
  inReceipt: '', // identificador de la factura asociada
  inClient: '',
  txClient: '',
  inAnimal: '',
  txAnimal: '',
  inEmployee: '',
  txEmployee: '',
  txNotes: '',
  //--------------------------
  nuTotalDiscount: '',
  nuTotalTaxable: '',
  nuTotalTax: '',
  nuTotalAmount: '',
  nuTotalWtaxPerc: '',
  nuTotalWtax: '',
  nuTotalFinal: '',
  nuTotalPayment: '',
  //--------------------------
  inInvoiceOri: '', //solo en albaranes
  inReceiptOri: '', //solo en facturas
  isVoid: '',
  balance: '',
  //--------------------------
  arRates: '',
  arItems: '',
  arPayms: '',
  txItemsDeleted: '',
  txPaymsDeleted: '',
};

// para compras
export const StorePurLst = {
  listType: '',
  listTypeBackground: '',
  // Cargados en MxPurDocList.changeDocType():
  table: '', // 'n_pur_quotation', 'n_pur_invoice', 'n_pur_receipt'
  table_idField: '', // 'inPurQuotation', 'inPurInvoice', 'inPurReceipt'
  table_txField: '', // 'txPurQuotation', 'txPurInvoice', 'txPurReceipt'
  table_dtField: '', // 'dtPurQuotation', 'dtPurInvoice', 'dtPurReceipt'
  table_item: '', // 'n_pur_quotation_item', 'n_pur_invoice_item', 'n_pur_receipt_item'
  table_item_idField: '', // 'inPurQuotationItem', 'inPurInvoiceItem'
  table_paym_idField: '', // 'inPayment'
  table_item_idx: '', // el índice dentro del array
  table_paym_idx: '', // el índice dentro del array
};
export const StorePurDoc = {
  // Cargados en MxPurDocList.openForm():
  boFirstRender: '', // flag que indica si es la primera vez que abre el doc, y no es navegación interna.
  boDataChanged: '', // flag que indica si el usuario cambió datos del documento
  //--------------------------
  docType: 'i', // i-albarán de compra, r-factura de compra, o-pedido
  nuStatus: '', // stock: 0-incluído en almacén, 1-excluído de almacén
  inPurXXX: '', // identificador del documento
  txPurXXX: '', // número de documento
  dtPurXXX: '', // fecha y hora del documento
  itSeries: '', // identificador de la serie
  txTypeSeries: '', // nombre de la serie
  inPurReceipt: '', // identificador de la factura asociada
  inSupplier: '',
  txSupplier: '',
  inEmployee: '',
  txEmployee: '',
  txContact: '',
  txReference: '',
  txNotes: '',
  //--------------------------
  nuTotalDiscount: '',
  nuTotalSurcharge: '',
  nuTotalTaxable: '',
  nuTotalTax: '',
  nuTotalAmount: '',
  nuTotalPayment: '',
  //--------------------------
  balance: '',
  //--------------------------
  arItems: '',
  arPayms: '',
  arItemsFq: '',
  txItemsDeleted: '',
  txPaymsDeleted: '',
};

export const StoreFile = { 
  url: '', 
  filter: '', 
  addressee: '', 
  txFileNameInput: '',
  obPDF: '',
  obCSV: '',
  _scale: '',
  _sales_slip_width: '',
  _sign: '',
  _send: '',
};

export const StoreComm = { 
  inComm: '', 
  inClient: '',
  inAnimal: '',
  inVacc: '',
  inRepl: '',
  dtFrom: '',
};

export const StoreWaiting = { 
  inWaiting: '', 
  dtIssue: '',
  inClient: '',
  inAnimal: '',
};

// otros
export const StoreStock = { 
  inStock: '',
  inPs: '',
  txPs: '',
};

export const StorePs = { inPs: '' };

export const StorePsComponent = { 
  irPsPs: '', 
  inPs: '', 
  inPsAttached: '', 
};

export const StoreLongTask = { 
  _url: '', 
  _record: '',
  _canCancel: '',
  _warning: '',
};

// para añadir ps en cns, vacc, rx_glas, rx_clen, etc
export const StoreSlsAux = { 
  inInvoiceItemX: '',
  inClient: '',
  strRelatedTable: '',
  strRelatedID: '',
  arRates: '',
};

// para archivo multimedia
export const StoreMultimedia = { inMultimedia: '' };

// para fichas de analítica financiera
export const StoreAnalytics = { inAnalytics: '' };

export const StoreSettPosTax = { itTax: '' };
export const StoreSettPosSeries = { itSeries: '' };
export const StoreSettPosCounter = { iiCounter: '' };
export const StoreSettPurSurcharge = { itSurcharge: '' };
export const StoreSettCusTranslation = { isTrans: '' };
export const StoreSettEmail = { itEmail: '' };
export const StoreSettSms = { itSms: '' };
export const StoreSettBranch = { inBranch: '' };
export const StoreSettBank = { inBank: '' };
export const StoreSettCash = { itCash: '' };
export const StoreSettClient = { itClient: '' };
export const StoreSettCpAccess = { itCpAccess: '' };
export const StoreSettMultimedia = { itMultimedia: '' };
export const StoreSettImage = { itImage: '' };
export const StoreSettEmployee = { itEmployee: '' };
export const StoreSettCusDoc = { itDoc: '' };
export const StoreSettSupplier = { itSupplier: '' };
export const StoreSettLab = { inLab: '' };
export const StoreSettLabTech = { inLabTech: '' };
export const StoreSettProf = { inProf: '' };
export const StoreSettPsGroup = { itPsGroup: '' };
export const StoreSettPsSubgroup = { itPsSubgroup: '' };
export const StoreSettAnimalSpecies = { itAnimalSpecies: '' };
export const StoreSettAnimalBreed = { itAnimalBreed: '' };
export const StoreSettAnimalCoat = { itAnimalCoat: '' };
export const StoreSettPaymentDet = { itPaymentDet: '' };
export const StoreSettCalendarCol = { itCalendarCol: '' };
export const StoreSettCalendarGrp = { itCalendarGrp: '' };
export const StoreSettRate = { itRate: '' };
export const StoreSettSecUser = { inUser: '' };
export const StoreSettSecGroup = { inSecGroup: '' };
export const StoreSettRxsSeries = { itSeriesRx: '' };
export const StoreSettComm = { itComm: '' };
export const StoreSettUserActions = { iiUser: '' };

// -------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------

export const loadSession = async (data) => {
  // LOGIN ->
  setStoreValue('StoreSession', 'inLogin', data.inLogin);
  setStoreValue('StoreSession', 'dtLogin', data.dtLogin);
  setStoreValue('StoreSession', 'txDevice', data.txDevice);
  setStoreValue('StoreSession', 'txLocale', data.txLocale);
  setStoreValue('StoreSession', 'txDecimalSeparator', data.txDecimalSeparator);
  setStoreValue('StoreSession', 'txDateFormat', data.txDateFormat);
  setStoreValue('StoreSession', 'txIdi', data.txIdi);
  setStoreValue('StoreSession', 'txApp', data.txApp);
  setStoreValue('StoreSession', 'txAppType', data.txAppType);
  setStoreValue('StoreSession', 'txEdition', data.txEdition);
  setStoreValue('StoreSession', 'inSecGroup', data.inSecGroup);
  setStoreValue('StoreSession', 'inUser', data.inUser);
  setStoreValue('StoreSession', 'txUser', data.txUser);
  setStoreValue('StoreSession', 'inClientCP', data.inClientCP); 
  setStoreValue('StoreSession', 'txCompany', data.txCompany);
  setStoreValue('StoreSession', 'inBranch', data.inBranch);
  setStoreValue('StoreSession', 'txBranch', data.txBranch);
  setStoreValue('StoreSession', 'txBranchId', data.txBranchId);
  setStoreValue('StoreSession', 'txBranchRgb', data.txBranchRgb);
  setStoreValue('StoreSession', 'boMainOffice', data.boMainOffice);
  setStoreValue('StoreSession', 'itCash', data.itCash);
  setStoreValue('StoreSession', 'txTypeCash', data.txTypeCash);
  setStoreValue('StoreSession', 'inEmployee', data.inEmployee);
  setStoreValue('StoreSession', 'txEmployee', data.txEmployee);
  setStoreValue('StoreSession', 'txLicense', data.txLicense);
  
  // BRANCH ->
  setStoreValue('StoreSession', 'txDefaultCity', data.txDefaultCity);
  setStoreValue('StoreSession', 'txDefaultState', data.txDefaultState);
  setStoreValue('StoreSession', 'txDefaultPostalCode', data.txDefaultPostalCode);
  setStoreValue('StoreSession', 'txDefaultCountry', data.txDefaultCountry);
  setStoreValue('StoreSession', 'nuIdleMinsUntilLock', data.nuIdleMinsUntilLock);
  setStoreValue('StoreSession', 'nuBarcodeType', data.nuBarcodeType);
  setStoreValue('StoreSession', 'nuDecimals', data.nuDecimals);
  setStoreValue('StoreSession', 'boDecimalsInDocs', data.boDecimalsInDocs);
  setStoreValue('StoreSession', 'txBranchHeaderInDocs', data.txBranchHeaderInDocs);
  setStoreValue('StoreSession', 'txBranchHeader', data.txBranchHeader);
  setStoreValue('StoreSession', 'txBranchFooter', data.txBranchFooter);
  setStoreValue('StoreSession', 'txPin', data.txPin);
  setStoreValue('StoreSession', 'txDataProtection', data.txDataProtection);
  setStoreValue('StoreSession', 'boAutoNumFiles', data.boAutoNumFiles);
  setStoreValue('StoreSession', 'boAutoFillTables', data.boAutoFillTables);
  setStoreValue('StoreSession', 'boToggleRowColor', data.boToggleRowColor);
  setStoreValue('StoreSession', 'boExactSearches', data.boExactSearches);
  setStoreValue('StoreSession', 'boShowBranchDataOnly', data.boShowBranchDataOnly);
  setStoreValue('StoreSession', 'boShowBranchPsOnly', data.boShowBranchPsOnly);
  setStoreValue('StoreSession', 'boElectronicPurse', data.boElectronicPurse);
  setStoreValue('StoreSession', 'boSynchronization', data.boSynchronization);
  setStoreValue('StoreSession', 'boConfirmExit', data.boConfirmExit);
  setStoreValue('StoreSession', 'boUseInvoices', data.boUseInvoices);
  setStoreValue('StoreSession', 'boUseReceipts', data.boUseReceipts);
  setStoreValue('StoreSession', 'boUsePurInvoices', data.boUsePurInvoices);
  setStoreValue('StoreSession', 'boUsePurReceipts', data.boUsePurReceipts);
  setStoreValue('StoreSession', 'boShowAllPurPs', data.boShowAllPurPs);
  setStoreValue('StoreSession', 'boAnnualNumeration', data.boAnnualNumeration);
  setStoreValue('StoreSession', 'boAutoSelEmployee', data.boAutoSelEmployee);
  setStoreValue('StoreSession', 'boUserLog', data.boUserLog);
  setStoreValue('StoreSession', 'boLockOldFiles', data.boLockOldFiles);
  setStoreValue('StoreSession', 'boUseUcaseForNames', data.boUseUcaseForNames);
  setStoreValue('StoreSession', 'boRequestPin', data.boRequestPin);
  setStoreValue('StoreSession', 'boPreventDuplicateCli', data.boPreventDuplicateCli);
  setStoreValue('StoreSession', 'boPreventDuplicatePss', data.boPreventDuplicatePss);
  setStoreValue('StoreSession', 'nuSalesTarget', data.nuSalesTarget);
  setStoreValue('StoreSession', 'boLotControl', data.boLotControl);
  setStoreValue('StoreSession', 'boWTaxByDefault', data.boWTaxByDefault);
  setStoreValue('StoreSession', 'nuWTaxPerc', data.nuWTaxPerc);
  setStoreValue('StoreSession', 'nuOverPricePerc', data.nuOverPricePerc);
  setStoreValue('StoreSession', 'boShowClientA4', data.boShowClientA4);
  setStoreValue('StoreSession', 'boShowClientA5', data.boShowClientA5);
  setStoreValue('StoreSession', 'boShowClientA8', data.boShowClientA8);
  setStoreValue('StoreSession', 'boFirstTabIsClient', data.boFirstTabIsClient);
  setStoreValue('StoreSession', 'boDefaultIsReceipt', data.boDefaultIsReceipt);
  setStoreValue('StoreSession', 'boConfirmationEnabled', data.boConfirmationEnabled);
  setStoreValue('StoreSession', 'boRememberSubmodules', data.boRememberSubmodules);
  setStoreValue('StoreSession', 'boCashControlReq', data.boCashControlReq);
  setStoreValue('StoreSession', 'boCashUpDateMatch', data.boCashUpDateMatch);
  setStoreValue('StoreSession', 'boUseDocsWord', data.boUseDocsWord);
  setStoreValue('StoreSession', 'boEnableOTF', data.boEnableOTF);
  setStoreValue('StoreSession', 'boCustomBlobsAutoDl', data.boCustomBlobsAutoDl);
  setStoreValue('StoreSession', 'itEmailForComm', data.itEmailForComm);
  setStoreValue('StoreSession', 'inUserToShowCommDlg', data.inUserToShowCommDlg);
  setStoreValue('StoreSession', 'txPcIdRemSender', data.txPcIdRemSender);
  setStoreValue('StoreSession', 'boHiddenReports', data.boHiddenReports);
  setStoreValue('StoreSession', 'boInvoicingUpdateDelete', data.boInvoicingUpdateDelete);
  
  // OPTI+VETT
  setStoreValue('StoreSession', 'boSpecialInvoice', data.boSpecialInvoice);
  setStoreValue('StoreSession', 'boSpecialReceipt', data.boSpecialReceipt);
  setStoreValue('StoreSession', 'boCashUpPendingPs', data.boCashUpPendingPs);
  setStoreValue('StoreSession', 'boAutoCreateFq', data.boAutoCreateFq);
  setStoreValue('StoreSession', 'boAutoCreateComm', data.boAutoCreateComm);
  // SOLO OPTI
  // SOLO VETT
  setStoreValue('StoreSession', 'boAutoCreateWa', data.boAutoCreateWa);
  setStoreValue('StoreSession', 'boAutoCreateRx', data.boAutoCreateRx);
  setStoreValue('StoreSession', 'boAutoCreateRxForce', data.boAutoCreateRxForce);
  setStoreValue('StoreSession', 'boAutoCreateEu', data.boAutoCreateEu);
  setStoreValue('StoreSession', 'boAutoCreateSt', data.boAutoCreateSt);
  setStoreValue('StoreSession', 'boAutoCreateMp', data.boAutoCreateMp);
  setStoreValue('StoreSession', 'boAnimalAlertMsg', data.boAnimalAlertMsg);
  setStoreValue('StoreSession', 'boRememberCnsSpc', data.boRememberCnsSpc);

  return;
}

export const clearStore = (storeName, store) => {
  // eslint-disable-next-line array-callback-return
  Object.keys(store).map(function(keyName, keyIndex) {
    store[keyName] = '';
  })
  sessionStorage.setItem(storeName, JSON.stringify(store));
  //sessionStorage.removeItem(storeName);
};

export const hasSessionStarted = () => {
  if (getStoreValue('StoreSession', 'inUser')) {
    return true;
  } else {
    return false;
  }
};

export const isApp = (txApp) => {
  let sessionApp = getStoreValue('StoreSession', 'txApp');
  let rv = txApp.includes(sessionApp);
  return rv;
};

export const isDesktop = () => {
  return getStoreValue('StoreSession', 'txDevice')==='Desktop/Tablet';
};

export const getStoreValueBo = (store, key) => {
  return Boolean(Number(getStoreValue(store, key)));
}
export const getStoreValue = (store, key) => {
  const theStore = sessionStorage.getItem(store);
  if (theStore) {
    const json = JSON.parse(theStore);
    return json[key];
  }
};

export const setStoreValue = (store, key, val) => {
  const theStore = JSON.parse(sessionStorage.getItem(store));
  if (theStore) {
    theStore[key] = val;
    sessionStorage.setItem(store, JSON.stringify(theStore));
  }
};

