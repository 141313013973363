import React, { useImperativeHandle } from "react";
import '../../styles/PickerDate/PickerDate.css';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// eslint-disable-next-line
import * as FxLog from '../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import '../../styles/main.css';
import CxDialog from "./CxDialog";

const useStylesLocal = makeStyles({
  frame: {
    backgroundColor: 'var(--ibs_color_white)',
    color: 'var(--ibs_color_black)',
    padding: '0px 0 0px 8px',
    height: props => props.height || '300px',
    width: props => props.width || 'var(--ibs_control_width_sm)',
  },

  radio: {
    padding: '0px 0 4px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 0px 0px 0px',
    cursor: 'default',
    textAlign: 'left',
    //color: 'var(--ibs_color_gray)',
    fontSize: 'var(--ibs_control_label_font_size_radio)',
    "&.MuiFormControlLabel-root": {
      width: props => {
        if (props.width) {
          return props.width;
        } else {
          return '100%'
        }
      },
    },
  },

});

const CxDlgRadioList = props => {
  const classesL = useStylesLocal(props);
  const { onOk, onOpen } = props;
  const [open, setOpen] = React.useState(false);
  const [lsItems, setLsItems] = React.useState([]);
  const [myValue, setMyValue] = React.useState('');
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    setLsItems(props.list);

    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    setMyValue(event.target.value);
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, setOpenAndFill,
    };
  });
  const getType = () => { return 'CxDlgRadioList' };

  const setOpenAndFill = (boOpen) => {
    if (boOpen) {
      if (onOpen) {
        setLsItems(onOpen());
      }
      setOpen(true);
    } else {
      setOpen(false);
    }
  }

  const doOk = () => {
    if (myValue) {
      onOk(myValue);
      setOpen(false);
    }
  }

  return (
    <div>
      <CxDialog
        title={props.title}
        open={open}
        onClose={() => setOpen(false)}
        onOk={doOk}
        height='400px'>

        <div className={classesL.frame}>

          <RadioGroup ref={radioGroupRef} value={myValue} onChange={handleChange} >
            {lsItems && lsItems.map((option) => (
              option.id && <FormControlLabel value={option.id} key={option.type} control={<Radio />} label={<Typography className={classesL.radio}>{option.type}</Typography>} />
            ))}
          </RadioGroup>

        </div>

      </CxDialog>
    </div>
  );
};

export default CxDlgRadioList;
