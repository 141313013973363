import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import * as FxLog from '../../fx/FxLog';
import '../../../styles/main.css';
import { StoreSettSms } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import * as FxFetch from '../../fx/FxFetch';
import CxDialog from '../../cx/CxDialog';
import CxSnackbar from '../../cx/CxSnackbar';
import CxIconButton from '../../cx/CxIconButton';
import i18n from '../../../i18n';


const MxSettCommSmsForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  let r_form = React.useRef(null);

  const r_cxdialog = React.useRef(null);

  const rf_test_txMobile = React.useRef('');

  const r_snack_test_result = React.useRef(null);
  const r_snack_credits_result = React.useRef(null);

  const [storeSettSms, setStoreSettSms] = useSessionStorage('StoreSettSms', StoreSettSms);
  const [dlgTest, setDlgTest] = React.useState(false);

  const rf_itSms = React.useRef(null);
  const rf_txName = React.useRef(null);
  const rf_txFrom = React.useRef(null);
  const rf_txCode = React.useRef(null);
  const rf_txUrlS = React.useRef(null);
  const rf_txUrlQ = React.useRef(null);
  const rf_txXmlLblBalance = React.useRef(null);
  const rf_txXmlLblResult = React.useRef(null);


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      let txBrowserLang = i18n.language;
      switch (txBrowserLang.substring(0,2)) {
        case 'en':
          rf_txName.current.setVal('SMS Account 1');
          rf_txFrom.current.setVal('My Business');
          rf_txCode.current.setVal('1'); //US
          break;
  
        case 'es':
          rf_txName.current.setVal('Cuenta SMS 1');
          rf_txFrom.current.setVal('Mi Negocio');
          rf_txCode.current.setVal('34'); //Spain
          break;
  
        default:
          break;
      }
      rf_txUrlS.current.setVal('http://sms.smsbroker.net:11000/api/sendSMS.php?user=<txUser>&pw=<txPass>&dnr=<txCode><txTo>&msg=<txContent>&snr=<txFrom>');
      rf_txUrlQ.current.setVal('http://sms.smsbroker.net:11000/api/balance.php?user=<txUser>&pw=<txPass>');
      rf_txXmlLblBalance.current.setVal('balance');
      rf_txXmlLblResult.current.setVal('status');
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus === 'error') {
    } else {
      storeSettSms.itSms = lastSaveRv.theValue;
      Promise.all([setStoreSettSms(storeSettSms)]).then((result) => {
        navBack();
      });
    }
  }
  const formDelete = async (rv) => {
  }

  const doTest = () => {
    try {
      setDlgTest(false);
      let txMobile = rf_test_txMobile.current.getVal();
      if (txMobile) {
        let record = {};
        record['txMobile'] = txMobile;
        Promise.all([FxFetch.doJsonX(`/sett/sms/test`, record)]).then((result) => {
          if (result[0].theValue === 'OK') {
            r_snack_test_result.current.setVal(result[0].theValue);
          } else {
            r_snack_test_result.current.setVal(result[0].theValue);
          }
          r_snack_test_result.current.setOpen(true);
        });
      }

    } catch (error) {
      FxLog.errorx("MxSettCommSmsForm.doTest", error);
    }
  };

  const doCheckCredits = () => {
    try {
      if (storeSettSms.itSms) {
        let record = {};
        record['itSms'] = storeSettSms.itSms;
        Promise.all([FxFetch.doJsonX(`/sett/sms/get_credits`, record)]).then((result) => {
          if (result[0].theValue.startsWith("rv_")) {
            r_snack_credits_result.current.setVal(trans('msg.error'));
          } else {
            r_snack_credits_result.current.setVal(trans('field.availability') + ': ' + result[0].theValue);
          }
          r_snack_credits_result.current.setOpen(true);
        });
      }

    } catch (error) {
      FxLog.errorx("MxSettCommSmsForm.doCheckCredits", error);
    }
  };

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.comms')}: ${trans('field.sms')}`}
        xref={r_form}
        url="sett/sms"
        filterIdField='itSms'
        filterIdValue={storeSettSms.itSms}
        onFormLoad={formLoad}
        onFormSave={formSave}
        onFormDelete={formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {storeSettSms.itSms && <CxIconButton type="test" classType="ibs_iconbutton_toolbar" onClick={() => setDlgTest(true)} tooltip={trans('field.test')} />}
            {storeSettSms.itSms && <CxIconButton type="info" classType="ibs_iconbutton_toolbar" onClick={() => doCheckCredits()} tooltip={trans('field.credits')} />}
          </div>
        }
      >
        <CxHidden xref={rf_itSms} dbfield="t_sms.itSms" value={storeSettSms.itSms || ''} />
        <CxInput xref={rf_txName} label={trans('field.name')} dbfield="t_sms.txName" required maxLength={50} />
        <CxInput xref={rf_txFrom} label={trans('field.sender')} dbfield="t_sms.txFrom" maxLength={11} />
        <CxInput xref={rf_txCode} label={trans('field.country_code')} dbfield="t_sms.txCode" maxLength={5} />
        <CxInput xref={useRef(null)} label={trans('field.user')} dbfield="t_sms.txUser" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.password')} dbfield="t_sms.txPass" maxLength={50} />

        <CxInput xref={rf_txUrlS} label={trans('field.sms_url_s')} dbfield="t_sms.txUrlS" minRows={2} />
        <CxInput xref={rf_txUrlQ} label={trans('field.sms_url_q')} dbfield="t_sms.txUrlQ" minRows={2} />
        <CxInput xref={rf_txXmlLblBalance} label={trans('field.xml_label') + ': ' + trans('field.balance')} dbfield="t_sms.txXmlLblBalance" maxLength={20} />
        <CxInput xref={rf_txXmlLblResult} label={trans('field.xml_label') + ': ' + trans('field.result')} dbfield="t_sms.txXmlLblResult" maxLength={20} />

      </GxForm>

      <CxDialog
        xref={r_cxdialog}
        title={`${trans('field.addressee')} (${trans('field.mobile')})`}
        open={dlgTest}
        onOk={doTest}
        onCancel={() => setDlgTest(false)}
        height='200px'>

        <CxInput xref={rf_test_txMobile} width="var(--ibs_control_width_md)" />
      </CxDialog>

      <CxSnackbar xref={r_snack_test_result} severity="warning" />
      <CxSnackbar xref={r_snack_credits_result} severity="info" />

    </div>
  );

}

export default MxSettCommSmsForm;
