import { BsHandThumbsDown } from "react-icons/bs"; 
import { TbClipboardCheck } from "react-icons/tb"; 
import { BsHandThumbsUp } from "react-icons/bs";
import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxButton from '../../cx/CxButton';
import CxIconButtonNew from '../../cx/CxIconButtonNew';
import CxDlgAlert from '../../cx/CxDlgAlert';
import * as FxLog from '../../fx/FxLog';
import { makeStyles } from '@material-ui/core/styles';
import '../../../styles/main.css';
import { StoreSettEmail } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import * as FxFetch from '../../fx/FxFetch';
import CxDialog from '../../cx/CxDialog';
import i18n from '../../../i18n';


const useStyles = makeStyles(theme => ({
  frame: {
    display: 'flex',
    gap: '5px',
    width: 'var(--ibs_control_width_lg)',
  },
  button: {
    flexGrow: '1',
    backgroundColor: 'var(--ibs_color_cy_blue)',
    color: 'var(--ibs_color_white)',
    '&:hover': { backgroundColor: 'var(--ibs_color_cy_blue)', },
  },
}));

const MxSettCommEmailForm = (props) => {
  const [{ trans }] = useTranslation();
  const classes = useStyles(props);

  let r_form = React.useRef(null);

  const r_dlg_test = React.useRef(null);
  const r_dlg_test_result = React.useRef(null);

  const rf_test_txEmail = React.useRef('');
  const rf_txName = React.useRef(null);

  const [storeSettEmail, setStoreSettEmail] = useSessionStorage('StoreSettEmail', StoreSettEmail);
  const [dlgTest, setDlgTest] = React.useState(false);


  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      let txBrowserLang = i18n.language;
      switch (txBrowserLang.substring(0,2)) {
        case 'en':
          rf_txName.current.setVal('EMail Account 1');
          break;
  
        case 'es':
          rf_txName.current.setVal('Cuenta EMail 1');
          break;
  
        default:
          break;
      }
    }
  }
  const formSave = async (lastSaveRv) => {
    if (lastSaveRv.theStatus === 'error') {
    } else {
      storeSettEmail.itEmail = lastSaveRv.theValue;
      Promise.all([setStoreSettEmail(storeSettEmail)]).then((result) => {
        //navBack(); // 2024-05-15 - daq: nos quedamos, por si el usuario quiere hacer una prueba.
      });
    }
  }
  const formDelete = async (rv) => {
  }

  const doGmail = () => {
    let props = "";
    props += "mail.smtp.host=smtp.gmail.com\n";
    props += "mail.smtp.port=587\n";
    props += "mail.smtp.auth=1\n";
    props += "mail.smtp.ssl.trust=*\n";
    props += "mail.smtp.starttls.enable=true\n";
    r_form.current.getRef(`t_email.txProps`).setVal(props);
  };

  const doOutlook = () => {
    let props = "";
    props += "mail.smtp.host=smtp-mail.outlook.com\n";
    props += "mail.smtp.port=587\n";
    props += "mail.smtp.auth=1\n";
    props += "mail.smtp.ssl.trust=*\n";
    props += "mail.smtp.starttls.enable=true\n";
    r_form.current.getRef(`t_email.txProps`).setVal(props);
  };

  const doTest = () => {
    try {
      setDlgTest(false);
      let txEmail = rf_test_txEmail.current.getVal();
      if (txEmail) {
        let record = {};
        record['txEmail'] = txEmail;
        Promise.all([FxFetch.doJsonX(`/sett/email/test`, record)]).then((result) => {
          if (result[0].theValue === 'OK') {
            r_dlg_test_result.current.setText(trans('msg.operation_successful'));
            r_dlg_test_result.current.setTextColor('var(--ibs_color_green)');
            r_dlg_test_result.current.setIcon(<BsHandThumbsUp fontSize="30px" style={{ fill: 'var(--ibs_color_green)', paddingBottom: '10px' }} />);
          } else {
            r_dlg_test_result.current.setText(result[0].theDesc);
            r_dlg_test_result.current.setTextColor('var(--ibs_color_red)');
            r_dlg_test_result.current.setIcon(<BsHandThumbsDown fontSize="30px" style={{ fill: 'var(--ibs_color_red)', paddingBottom: '10px' }} />);
          }
          r_dlg_test_result.current.setOpen(true);
        });
      }

    } catch (error) {
      FxLog.errorx("MxSettCommEmailForm.doTest", error);
    }
  };

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.comms')}: ${trans('field.email')}`}
        xref={r_form}
        url="sett/email"
        filterIdField='itEmail'
        filterIdValue={storeSettEmail.itEmail}
        onFormLoad={formLoad}
        onFormSave={formSave}
        onFormDelete={formDelete}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {storeSettEmail.itEmail && <CxIconButtonNew icon={<TbClipboardCheck fontSize="24px" style={{paddingTop:'0px'}} />} classType="ibs_iconbutton_toolbar" onClick={() => setDlgTest(true)} tooltip={trans('field.test')} />}
          </div>
        }
      >
        <CxHidden xref={useRef(null)} dbfield="t_email.itEmail" value={storeSettEmail.itEmail || ''} />
        <CxInput xref={rf_txName} label={trans('field.name')} dbfield="t_email.txName" required maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.user')} dbfield="t_email.txUser" maxLength={50} />
        <CxInput xref={useRef(null)} label={trans('field.password')} dbfield="t_email.txPass" type="password" maxLength={50} />
        <CxInput xref={useRef(null)} dbfield="t_email.txProps" minRows={10} />
        <br />
        <div className={classes.frame}>
          <CxButton label={'GMail'} onClick={doGmail} />
          <CxButton label={'Outlook'} onClick={doOutlook} />
        </div>
      </GxForm>

      <CxDialog
        xref={r_dlg_test}
        title={`${trans('field.addressee')} (${trans('field.email')})`}
        open={dlgTest}
        onOk={doTest}
        onCancel={() => setDlgTest(false)}
        height='200px'>

        <CxInput xref={rf_test_txEmail} width="var(--ibs_control_width_md)" />
      </CxDialog>

      <CxDlgAlert xref={r_dlg_test_result} hasButtonClose />


    </div>
  );

}

export default MxSettCommEmailForm;
