import React from 'react';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxInput from '../../cx/CxInput';
import CxSearcher from '../../cx/CxSearcher';
import CxNumber from '../../cx/CxNumber';
import CxHidden from '../../cx/CxHidden';
import CxDate from '../../cx/CxDate';
import * as FxFetch from "../../fx/FxFetch";
import * as FxLog from '../../fx/FxLog';
import * as FxNum from '../../fx/FxNum';
import * as FxTable from '../../fx/FxTable';
import GxForm from '../../gx/GxForm';
import MxPsOTF from '../common/otf/MxPsOTF';
import '../../../styles/main.css';
import { StorePurLst, StorePurDoc, } from "../../gx/GxStore";
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxIconButton from '../../cx/CxIconButton';
import { useWindowSize } from '../../hx/useWindowSize';
import { useAccess } from '../../hx/useAccess';
import CxSnackbar from '../../cx/CxSnackbar';


const MxPurDocFormItem = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.pos'));
  const [{ navBack }] = useNav();
  const size = useWindowSize();

  let r_form = React.useRef(null);
  let r_dlg_new_ps = React.useRef(null);
  const r_snack_error = React.useRef(null);

  const [docTypeName, setdocTypeName] = React.useState(null);

  const rf_txPs = React.useRef(null);
  const rf_nuConversionFactor = React.useRef(null);
  const rf_nuPackages = React.useRef(null);
  const rf_txLotCode = React.useRef(null);
  const rf_dtLotExpr = React.useRef(null);

  const rf_nuQuantity = React.useRef(null);
  const rf_nuPrice = React.useRef(null);
  const rf_nuDiscountPerc = React.useRef(null);
  const rf_nuSurchargePerc = React.useRef(null);
  const rf_nuTaxPerc = React.useRef(null);
  const rf_nuAmount = React.useRef(null);

  const rf_txRemarks = React.useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [storePurLst, setStorePurLst] = useSessionStorage('StorePurLst', StorePurLst);
  const [storePurDoc, setStorePurDoc] = useSessionStorage('StorePurDoc', StorePurDoc);

  let r_idx = React.useRef(storePurLst.table_item_idx);

  React.useEffect(() => {
    if (storePurDoc.docType === 'o') setdocTypeName(trans('field.order'));
    if (storePurDoc.docType === 'i') setdocTypeName(trans('field.invoice'));
    if (storePurDoc.docType === 'r') setdocTypeName(trans('field.receipt'));
    formLoadCustom();

    // eslint-disable-next-line
  }, []);

  const formLoadCustom = () => {
    if (storePurLst.table_item_idx === null) { //nuevo registro
      rf_txPs.current.setDlg(true); /* DAQ 2022.05.30 - abre automáticamente ventana de selección de producto-servicio */

    } else { // registro existente
      rf_txPs.current.setKey(storePurDoc.arItems[r_idx.current].inPs);
      rf_txPs.current.setVal(storePurDoc.arItems[r_idx.current].txPs);
      rf_nuConversionFactor.current.setVal(storePurDoc.arItems[r_idx.current].nuConversionFactor);
      rf_nuPackages.current.setVal(storePurDoc.arItems[r_idx.current].nuPackages);
      rf_txLotCode.current.setVal(storePurDoc.arItems[r_idx.current].txLotCode);
      rf_dtLotExpr.current.setVal(storePurDoc.arItems[r_idx.current].dtLotExpr);
      rf_txRemarks.current.setVal(storePurDoc.arItems[r_idx.current].txRemarks);

      rf_nuQuantity.current.setVal(storePurDoc.arItems[r_idx.current].nuQuantity);
      rf_nuPrice.current.setVal(storePurDoc.arItems[r_idx.current].nuPrice);
      rf_nuDiscountPerc.current.setVal(storePurDoc.arItems[r_idx.current].nuDiscountPerc);
      rf_nuSurchargePerc.current.setVal(storePurDoc.arItems[r_idx.current].nuSurchargePerc);
      rf_nuTaxPerc.current.setVal(storePurDoc.arItems[r_idx.current].nuTaxPerc);
      rf_nuAmount.current.setVal(storePurDoc.arItems[r_idx.current].nuAmount);
    }
  }
  const formDeleteCustom = () => {
    if (storePurLst.table_item_idx !== null) {
      if (storePurDoc.arItems[r_idx.current].inPurXxxItem) {
        storePurDoc.txItemsDeleted = storePurDoc.arItems[r_idx.current].inPurXxxItem + ' ' + storePurDoc.txItemsDeleted;
      }
      storePurDoc.arItems.splice(r_idx.current, 1); // 2nd parameter means remove one item only
      // reorder
      storePurDoc.arItems.forEach(function (item, index) {
        item.idx = index;
      })

      // asienta que los datos fueron cambiados
      storePurDoc.boDataChanged = '1';

      Promise.all([setStorePurDoc(storePurDoc)]).then((result) => {
        navBack();
      });
    }
  }

  const recalc = async (from) => {
    //realiza cálculos a partir de los valores
    try {
      let nuPackages = rf_nuPackages.current.getFloatVal();
      let nuConversionFactor = rf_nuConversionFactor.current.getFloatVal();
      let nuQuantity = rf_nuQuantity.current.getFloatVal();
      if (nuPackages>0.0 && nuConversionFactor>0.0) {
        nuQuantity = FxNum.to3r(nuPackages * nuConversionFactor);
        rf_nuQuantity.current.setFloatVal(nuQuantity);
      }
      let nuPrice = rf_nuPrice.current.getFloatVal();
      let nuDiscountPerc = rf_nuDiscountPerc.current.getFloatVal();
      let nuSurchargePerc = rf_nuSurchargePerc.current.getFloatVal();
      let nuTaxPerc = rf_nuTaxPerc.current.getFloatVal();
      let nuAmount = rf_nuAmount.current.getFloatVal();

      let nuDiscount = 0.0;
      let nuSurcharge = 0.0;
      let nuTaxable = 0.0;
      let nuTax = 0.0;

      if (from === 'amount') {
				nuPrice = ((100 * nuAmount) + (100 * nuDiscount) + (nuTaxPerc * nuDiscount)) / (nuQuantity * (nuTaxPerc + nuSurchargePerc + 100));
      }
      nuDiscount = (nuQuantity * nuPrice * nuDiscountPerc / 100);
      nuSurcharge = (nuQuantity * nuPrice * nuSurchargePerc / 100);
      nuTaxable = ((nuQuantity * nuPrice) - nuDiscount);
      nuTax = (nuTaxable * nuTaxPerc / 100);
      
      nuAmount = FxNum.to3r(nuTaxable + nuTax + nuSurcharge);

      rf_nuPrice.current.setVal(nuPrice);
      rf_nuAmount.current.setVal(nuAmount);

    } catch (error) {
      FxLog.errorx("MxPurDocFormItem.recalc", error);
    }
  }

  const clearValues = () => {
    rf_nuConversionFactor.current.setVal('');
    rf_nuPackages.current.setVal('');
    rf_txLotCode.current.setVal('');
    rf_dtLotExpr.current.setVal('');
    rf_nuQuantity.current.setVal('');
    rf_nuPrice.current.setVal('');
    rf_nuDiscountPerc.current.setVal('');
    rf_nuSurchargePerc.current.setVal('');
    rf_nuTaxPerc.current.setVal('');
    rf_nuAmount.current.setVal('');
  }

  const formLoadSelected = async (inPs) => {
    try {
      let record = {};
      record['idValue'] = inPs;
      Promise.all([FxFetch.doJsonX(`pur/docitem/load_selected`, record)]).then((result) => {
        if (result[0]) {
          // cargar controles
          rf_nuConversionFactor.current.setVal(result[0][0].nuConversionFactor);
          rf_nuPackages.current.setVal(result[0][0].nuConversionFactor>0 ? '1' : '');
          rf_nuQuantity.current.setVal(result[0][0].nuQuantity);
          rf_nuPrice.current.setVal(result[0][0].nuCost);
          rf_nuDiscountPerc.current.setVal(result[0][0].nuDiscountPerc);
          rf_nuSurchargePerc.current.setVal(result[0][0].nuSurchargePerc);
          rf_nuTaxPerc.current.setVal(result[0][0].nuTaxPerc);
          rf_nuAmount.current.setVal("0");

          // recálculo
          recalc();
        }
      });

    } catch (error) {
      FxLog.errorx("MxPurDocFormItem.formLoadSelected", error);
    }
  }

  const formSaveCustom = () => {
    if (r_form.current.hasIncompleteRequired()) {
      return;
    }
    if (!rf_txLotCode.current.isEmpty() && rf_dtLotExpr.current.isEmpty()) {
      r_snack_error.current.setValAndOpen(trans('msg.missing_x', 'field.expiration'));
      return;
    }

    let inPs = rf_txPs.current.getKey();
    Promise.all([saveItemToStore(inPs)]).then((result) => {
      navBack();
    });

  }

  const saveItemToStore = (inPs) => {
    try {
      let items = storePurDoc.arItems || [];
      /*console.log("# of items: " + items.length);
      items.forEach(function(item, index) {
        console.log("[" + index + "]: " + item.txPs);
      });*/

      let _inPs = rf_txPs.current.getKey();
      let _txPs = rf_txPs.current.getVal();
      let _nuConversionFactor = rf_nuConversionFactor.current.getVal();
      let _nuPackages = rf_nuPackages.current.getVal();
      let _txLotCode = rf_txLotCode.current.getVal();
      let _dtLotExpr = rf_dtLotExpr.current.getVal();
      let _txRemarks = rf_txRemarks.current.getVal();

      let _nuQuantity = rf_nuQuantity.current.getFloatVal();
      let _nuPrice = rf_nuPrice.current.getFloatVal();
      let _nuDiscountPerc = rf_nuDiscountPerc.current.getFloatVal();
      let _nuSurchargePerc = rf_nuSurchargePerc.current.getFloatVal();
      let _nuTaxPerc = rf_nuTaxPerc.current.getFloatVal();
      let _nuAmount = FxNum.to2r(rf_nuAmount.current.getFloatVal());
      let _nuDiscount = (_nuQuantity * _nuPrice * _nuDiscountPerc / 100);
      let _nuSurcharge = (_nuQuantity * _nuPrice * _nuSurchargePerc / 100);
      let _nuTaxable = ((_nuQuantity * _nuPrice) - _nuDiscount);
      let _nuTax = (_nuTaxable * _nuTaxPerc / 100);

      if (storePurLst.table_item_idx === null) {
        // Adding
        let item = {
          idx: 0,
          inPurXxxItem: '',
          inPs: _inPs,
          txPs: _txPs,
          nuConversionFactor: _nuConversionFactor,
          nuPackages: _nuPackages,
          txLotCode: _txLotCode,
          dtLotExpr: _dtLotExpr,
          txRemarks: _txRemarks,

          nuQuantity: _nuQuantity,
          nuPrice: _nuPrice,
          nuDiscountPerc: _nuDiscountPerc,
          nuSurchargePerc: _nuSurchargePerc,
          nuTaxPerc: _nuTaxPerc,
          nuAmount: _nuAmount,
          //----
          nuSurcharge: _nuSurcharge,
          nuTaxable: _nuTaxable,
          nuTax: _nuTax,
          nuDiscount: _nuDiscount,
        };
        items.unshift(item);
        // reorder
        items.forEach(function (item, index) {
          item.idx = index;
        })
      } else {
        // Modifying
        storePurDoc.arItems[r_idx.current].idx = storePurLst.table_item_idx;
        storePurDoc.arItems[r_idx.current].inPs = _inPs;
        storePurDoc.arItems[r_idx.current].txPs = _txPs;
        storePurDoc.arItems[r_idx.current].nuPackages = _nuPackages;
        storePurDoc.arItems[r_idx.current].txLotCode = _txLotCode;
        storePurDoc.arItems[r_idx.current].dtLotExpr = _dtLotExpr;
        storePurDoc.arItems[r_idx.current].txRemarks = _txRemarks;

        storePurDoc.arItems[r_idx.current].nuQuantity = _nuQuantity;
        storePurDoc.arItems[r_idx.current].nuPrice = _nuPrice;
        storePurDoc.arItems[r_idx.current].nuDiscountPerc = _nuDiscountPerc;
        storePurDoc.arItems[r_idx.current].nuSurchargePerc = _nuSurchargePerc;
        storePurDoc.arItems[r_idx.current].nuTaxPerc = _nuTaxPerc;
        storePurDoc.arItems[r_idx.current].nuAmount = _nuAmount;
        //----
        storePurDoc.arItems[r_idx.current].nuSurcharge = _nuSurcharge;
        storePurDoc.arItems[r_idx.current].nuTaxable = _nuTaxable;
        storePurDoc.arItems[r_idx.current].nuTax = _nuTax;
        storePurDoc.arItems[r_idx.current].nuDiscount = _nuDiscount;
      }

      //console.log("Added item: " + item.txPs);
      storePurDoc.arItems = items;

      // asienta que los datos fueron cambiados
      storePurDoc.boDataChanged = '1';

      // Saving
      setStorePurDoc(storePurDoc);

    } catch (error) {
      FxLog.errorx('MxPurDocFormItem.saveItemToStore', error)
    }

  }

  const psChanged = async (row) => {
    if (row !== null) {
      clearValues();
      let inPs = row.original.inPs;
      formLoadSelected(inPs);
    }
  }

  const selectedPsOTF = (inPs, txPs) => {
    try {
      rf_txPs.current.setKey(inPs);
      rf_txPs.current.setVal(txPs);
      formLoadSelected(inPs);

    } catch (error) {
      FxLog.errorx('MxPurDocFormItem.selectedPsOTF', error)
    }
  }

  return (
    <div>
      <GxForm
        moduleName={`${trans('field.purchases')}: ${docTypeName}: ${trans('field.item')}`}
        xref={r_form}
        toolbarBackground={storePurLst.listTypeBackground}
        addToolbarButtons={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* botón borrar */}
            {storePurLst.table_item_idx!==null && isFullAccess() 
              && <CxIconButton type="cancel" classType="ibs_iconbutton_toolbar" onClick={() => formDeleteCustom()} tooltip={trans('field.delete')} />}

            {/* botón guardar */}
            {isFullAccess() 
              && <CxIconButton type="accept" classType="ibs_iconbutton_toolbar" onClick={() => formSaveCustom()} tooltip={trans('field.confirm')} />}
          </div>
        }
      >

        <CxHidden xref={rf_nuConversionFactor} />

        <CxSearcher xref={rf_txPs} id='MxPurDocFormItem_txPs'
          label={trans('field.ps')}
          readOnly={storePurLst.table_item_idValue}
          onSelection={psChanged}
          onBlur={recalc}
          onClear={clearValues}
          onButtonAddOTF={() => {
            rf_txPs.current.setDlg(false);
            r_dlg_new_ps.current.setDlg(true);
          }}
          url="pss"
          col_id="inPs"
          col_tx="txPs"
          filter={'n_ps.nuNature = 0'}
          tooltip={`${trans('field.ps')} / ${trans('field.code')}`}
          colorRefs={ [ 'table_cell_brown|' + trans('field.composite'), 'table_cell_green|' + trans('field.frequency') ] }
          columns={[
            { id: "0", Header: "ID", accessor: "inPs", show: false },
            { id: "1", Header: trans('field.ps'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPs"]), width: '50%' },
            { id: "2", Header: trans('field.code'), accessor: row => rf_txPs.current.getTable().highlightText(row["txPsCode"]), width: '25%', align: 'right', show: size.isLarge },
            { id: "3", Header: trans('field.total'), accessor: row => FxTable.cellTo2r00(row["theTotalCost"]), width: '25%', align: 'right' },
            { id: "W", Header: "_composite", accessor: row => Boolean(row["boComposite"] === '1'), show: false },
            { id: "G", Header: "_frequency", accessor: row => Boolean(row["nuFrequency"] > 0), show: false },
            ]}
          required
        />

        <CxNumber xref={rf_nuPackages} label={trans('field.packages')} sumButton onBlur={recalc} />
        <CxInput xref={rf_txLotCode} label={trans('field.lot')} maxLength="50" />
        <CxDate xref={rf_dtLotExpr} label={trans('field.expiration')} />

        <CxNumber xref={rf_nuQuantity} label={trans('field.quantity')} sumButton onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuPrice} label={trans('field.price')} onBlur={recalc} decimals={4} decimalsfixed="true" required />
        <CxNumber xref={rf_nuDiscountPerc} label={trans('field.discount_perc')} onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuTaxPerc} label={trans('field.tax_perc')} onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuSurchargePerc} label={trans('field.surcharge_perc')} onBlur={recalc} decimals={2} decimalsfixed="true" required />
        <CxNumber xref={rf_nuAmount} label={trans('field.amount')} onBlur={() => recalc('amount')} decimals={2} decimalsfixed="true" required />
        <CxInput xref={rf_txRemarks} label={trans('field.remarks')} />

        <MxPsOTF xref={r_dlg_new_ps} onOk={selectedPsOTF} />

      </GxForm>
      <CxSnackbar xref={r_snack_error} severity="error" />

    </div>
  );

}

export default MxPurDocFormItem;
