import React from 'react';
import IconButton from '@material-ui/core/IconButton';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import CxTooltip from './CxTooltip';


export default function CxIconButtonNew(props) {
  const { xref, onClick, classType, data, ...rest } = props;

  const [state, setState] = React.useState(rest.state || 'OFF'); // 2024-07-05 - daq: si es que el botón utiliza estado prendido/apagado.
  const [icon, setIcon] = React.useState(rest.icon);

  React.useImperativeHandle(xref, () => {
    return {
      getState, setIcon,
    };
  });
  const getState = () => { return state };

  const doClick = (event) => {
    if (state==='ON') {
      setState('OFF');
    } else {
      setState('ON');
    }

    if (onClick) {
      onClick(data||event);
    }
  };

  return (
    <div>
      <CxTooltip text={rest.tooltip ? rest.tooltip : null}>
        <span>
          <IconButton className={classType ? classType : "ibs_iconbutton"} onClick={doClick} disabled={rest.disabled} tabIndex='-1' {...rest}>
            {icon}
          </IconButton>
        </span>
      </CxTooltip>
    </div>
  );
}
