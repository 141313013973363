import { GiSpeaker } from "react-icons/gi"; 
import React from "react";
import { useTable } from "react-table";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import * as FxFetch from '../fx/FxFetch';
import * as FxStr from '../fx/FxStr';
import * as FxDat from '../fx/FxDat';
import * as FxTable from '../fx/FxTable';
import CxInput from '../cx/CxInput';
import CxDate from '../cx/CxDate';
import CxCombo from '../cx/CxCombo';
import CxHr from '../cx/CxHr';
import { useTranslation } from '../hx/useTranslation';
import '../../styles/main.css';
import '../../styles/Table/Table.css';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';
import CxDialog from './CxDialog';
import CxProgress from "./CxProgress";
import CxMultiCheck from "./CxMultiCheck";
import CxDlgAlert from "./CxDlgAlert";
import { useWindowSize } from '../hx/useWindowSize';
import CxIconButton from "./CxIconButton";
import CxIconButtonNew from "./CxIconButtonNew";
import { IoCaretForwardCircleOutline } from "react-icons/io5";
import { IoCaretBackCircleOutline } from "react-icons/io5";
import { IoPlaySkipBackCircleOutline } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import CxDlgUpgrade from './CxDlgUpgrade';
import { BiInfoCircle } from "react-icons/bi";
import { getStoreValue } from "../gx/GxStore";


const CxTable = ({ onOpenForm, onOptions, onSpecialOptions, onFetchPage, columns, ...rest }) => {
  const [{ trans }] = useTranslation();
  const size = useWindowSize();
  const MIN_WIDTH = 700;
  const BUTTON_FONTSIZE = 28;

  const [progress, setProgress] = React.useState(false);

  const [tooltip, setTooltip] = React.useState(rest.tooltip);
  const [search, setSearch] = React.useState('');
  const [pageNo, setPageNo] = React.useState(0);
  const [data, setPageData] = React.useState([]);
  const [filter, setFilter] = React.useState(rest.filter || '');
  const [filterHaving, setFilterHaving] = React.useState(rest.filterHaving || '');
  const [addParams, setAddParams] = React.useState(rest.addParams || '');

  let txUrl = React.useRef(rest.url);

  const rf_search = React.useRef(null);
  const rf_button_action1 = React.useRef(null);
  const rf_optional_columns = React.useRef(null);
  const rf_optional_sorting = React.useRef(null);
  const rf_optional_from = React.useRef(null);
  const rf_optional_to = React.useRef(null);

  const r_is_editing = React.useRef(false);
  const r_is_empty = React.useRef(true);
  const r_dlg_alert = React.useRef(null);

  let r_dlg_upgrade = React.useRef(null);

  const [dlgOptions, setDlgOptions] = React.useState(false);

  const [dlgInfo, setDlgInfo] = React.useState(false);
  const [infoTotal, setInfoTotal] = React.useState(false);

  const [isRequired, setIsRequired] = React.useState(false);

  const {
    getTableProps,
    headerGroups,
    rows,
    allColumns,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: columns.map(column => {
        if (column.show === false) { return column.id } else { return null };
      }),
    }
  });

  React.useEffect(() => {
    if (rest.optionalSorting && !rest.id) {
      FxLog.errorx("CxTable.useEffect", "THIS TABLE HAS ORDERING BUT NEEDS ID!!");
    }
    setIsRequired(rest.required !== undefined);

    let search = sessionStorage.getItem('CxTable.' + rest.url + (rest.id || '') + '_search') || "";
    let pageno = Number(sessionStorage.getItem('CxTable.' + rest.url + (rest.id || '') + '_pageno') || "0");
    if (rest.hasSearchBox) {
      if (search.length > 0) {
        rf_search.current.setVal(search);
        Promise.all([setSearch(search)]).then((result) => { // 2024-01-30 - daq
          fetchPage(search, pageNo); /* DAQ 2022.05.22 - para que vuelva a la página con búsqueda si viene de una ficha */
        });
      } else {
        if (window.innerWidth > MIN_WIDTH) {
          // solo pone foco si es pantalla grande, porque en el móvil haría aparecer el teclado y reducirse el listado.
          if (rest.focusSearchBox) {
            rf_search.current.setFocus();
          }
        }
      }
    }
    fetchPage(search, pageno);

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    function handleResize() {
      fetchPage(search, 0);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return () => {
      if (!r_is_editing.current) {
        sessionStorage.removeItem('CxTable.' + rest.url + (rest.id || '') + '_search');
        sessionStorage.removeItem('CxTable.' + rest.url + (rest.id || '') + '_pageno');
      }
      r_is_editing.current = false;
    };
    // eslint-disable-next-line
  }, []);


  React.useImperativeHandle(rest.xref, () => {
    return {
      getType, redisplay, refresh, isEmpty, getIsRequired, getLabel, highlightText, highlightDate, getFilter, setFilter, getFilterHaving, setFilterHaving,
      setAddParams, setSearchForced, setTooltipText, getTooltipText, clearSearch, getSearchText, getOptionalFrom, getOptionalTo, goPagePrev, goPageNext,
    };
  });
  const getType = () => { return 'CxTable' };
  const getFilter = () => { return filter };
  const getFilterHaving = () => { return filterHaving };
  const getIsRequired = () => { return isRequired && isEmpty() };
  const getLabel = () => { return rest.label || '' }; /* DAQ 2022.05.13 - trabaja con required */
  const goPageFirst = () => handleChangePage(0);
  const goPagePrev = () => handleChangePage(pageNo === 0 ? pageNo : pageNo - 1);
  const goPageNext = () => handleChangePage(pageNo + 1);

  const setTooltipText = (theTooltip) => {
    setTooltip(theTooltip);
    rf_search.current.setTooltipText(theTooltip);
  };

  const getTooltipText = () => {
    return rf_search.current.getTooltipText();
  };

  const setSearchForced = (search) => {
    if (search) {
      rf_search.current.setVal(search);
      Promise.all([setSearch(search)]).then((result) => {
        fetchPage(search, 0);
      });
    }
  };

  const redisplay = (url) => {
    if (url) {
      txUrl.current = url;
    }
    fetchPage(search, 0);
  };

  const refresh = () => {
    fetchPage(search, pageNo);
  };

  const isEmpty = () => {
    return r_is_empty.current;
  };

  const escapeRegExpMatch = function (s) { return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&') };
  const isExactMatch = (str, match) => { return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str) }

  /* DAQ 2022.05.22 -  */
  const highlightText = (cellVal) => {
    // Split on highlight term and include term into parts, ignore case
    if (cellVal && rest.hasSearchBox) {
      let highlight = search;
      if (search) {
        if (highlight.includes(':')) {
          let tip = tooltip.toLowerCase().replaceAll(' / ', '/')
          let field = highlight.split(':')[0].toLowerCase();
          let value = highlight.split(':')[1].trim().toLowerCase();
          if (isExactMatch(tip, field)) {
            return FxStr.highlightSearch(cellVal, value);
          } else {
            return cellVal;
          }

        } else if (highlight.includes('=')) {
          let tip = tooltip.toLowerCase().replaceAll(' / ', '/')
          let field = highlight.split('=')[0].toLowerCase();
          let value = highlight.split('=')[1].trim().toLowerCase();
          if (isExactMatch(tip, field)) {
            return FxStr.highlightSearch(cellVal, value);
          } else {
            return cellVal;
          }

        } else if (highlight.includes('+')) {
          let fields = highlight.split('+');
          let rv;
          for (let index = 0; index < fields.length; index++) {
            const hl = fields[index].trim();
            if (cellVal.toLowerCase().includes(hl.toLowerCase())) {
              rv = FxStr.highlightSearch(cellVal, hl);
            }
          }
          return rv || cellVal;

        } else {
          return FxStr.highlightSearch(cellVal, highlight);
        }
      } else {
        return cellVal;
      }
    }
  }

  const highlightDate = (cellVal) => {
    if (cellVal && rest.hasSearchBox) {
      let highlight = search;
      if (highlight.includes('+')) {
        let fields = highlight.split('+');
        let rv;
        for (let index = 0; index < fields.length; index++) {
          const hl = fields[index].trim();
          cellVal = cellVal.substring(0, 10);
          if (cellVal.toLowerCase().includes(FxDat.toIsoDate(hl))) {
            if (search && FxDat.isValidDtIso(FxDat.toIsoDate(hl))) {
              return FxStr.highlightSearch(FxTable.cellToLocalDate(cellVal), hl);
            }
          }
        }
        return rv || cellVal;

      } else {
        if (search && FxDat.isValidDtIso(FxDat.toIsoDate(highlight))) {
          return FxStr.highlightSearch(FxTable.cellToLocalDate(cellVal), highlight);
        } else {
          return FxTable.cellToLocalDate(cellVal);
        }
      }
    }
  }

  /* DAQ 2021.09.01 -  */
  const getRowColor = (cell, colIndex) => {
    let rv = 'table_cell';
    allColumns.forEach((column, iCol) => {
      let value = cell.row.allCells[iCol].value;
      if (value) {
        if (column.id === 'R') rv = 'table_cell_red';
        if (column.id === 'G') rv = 'table_cell_green';
        if (column.id === 'B') rv = 'table_cell_blue';
        if (column.id === 'Y') rv = 'table_cell_yellow';
        if (column.id === 'W') rv = 'table_cell_brown';
        if (column.id === 'A') rv = 'table_cell_gray';
      }
    });
    return rv;
  }

  /* by daq 06.08.21 */
  const getHeaderSetting = (colIndex, setting) => {
    let rv = '';
    headerGroups.forEach(headerGroup => {
      headerGroup.headers.forEach((column, iCol) => {
        if (colIndex === iCol) {
          rv = column[setting];
        }
      });
    });
    return rv;
  }

  const handleChangePage = (newPageNo) => {
    fetchPage(search, newPageNo);
  };

  const formAdd = () => {
    if (!rest.disabled) {
      if (rest.disabledUntilUpgrade) {
        r_dlg_upgrade.current.setOpen(true);
      } else {
        r_is_editing.current = true;
        if (onOpenForm) {
          onOpenForm("c", null);
        }
      }
    }
  };

  const formUpdate = (cell, ctrlKey) => {
    if (!rest.disabled) {
      r_is_editing.current = true;
      if (onOpenForm) {
        let col_id = cell.column.id;
        if (!String(col_id).startsWith('BTN_')) { // 2023-05-09 - daq: si no se pulsó algún botón de línea.
          let rowAndMore = { ...cell.row, col_id, ctrlKey };
          onOpenForm("u", rowAndMore);
        }
      }
    }
  };

  const clearSearch = () => {
    rf_search.current.setVal('');
    Promise.all([setSearch('')]).then((result) => {
      fetchPage('', 0);
    });
  };

  const getSearchText = () => {
    return rf_search.current.getVal();
  };

  const getOptionalFrom = () => {
    return sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_from');
  };
  const getOptionalTo = () => {
    return sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_to');
  };

  const optionsSelected = () => {
    try {
      setDlgOptions(false);

      if (onOptions) {
        if (rf_optional_columns.current) {
          onOptions(rf_optional_columns.current.getVal());
        }
      }

      if (rf_optional_from.current && rf_optional_to.current) {
        let from = rf_optional_from.current.getVal();
        let to = rf_optional_to.current.getVal();
        if (from.length === 0) {
          sessionStorage.removeItem('CxTable.' + (rest.id || '') + '_date_from');
        } else {
          sessionStorage.setItem('CxTable.' + (rest.id || '') + '_date_from', from);
        }
        if (to.length === 0) {
          sessionStorage.removeItem('CxTable.' + (rest.id || '') + '_date_to');
        } else {
          sessionStorage.setItem('CxTable.' + (rest.id || '') + '_date_to', to);
        }
        // redesplegamos para que muestre ordenamiento
        redisplay();
      }

      if (rf_optional_sorting.current) {
        let sortingField = rf_optional_sorting.current.getKey();
        if (sortingField.length === 0) {
          sessionStorage.removeItem('CxTable.' + (rest.id || '') + '_sorting');
        } else {
          sessionStorage.setItem('CxTable.' + (rest.id || '') + '_sorting', sortingField);
        }
        // redesplegamos para que muestre ordenamiento
        redisplay();
      }

    } catch (error) {
      FxLog.errorx("CxTable.optionsSelected", error);
    }
  }

  // Fetch page
  const fetchPage = async (newSearch, newPageNo) => {

    if (rest.hasSearchBox) {
      sessionStorage.setItem('CxTable.' + rest.url + (rest.id || '') + '_search', newSearch);
    }
    sessionStorage.setItem('CxTable.' + rest.url + (rest.id || '') + '_pageno', newPageNo);
    //-----------------------------------------------------------------------
    let pageSize = null;
    let spaceForSearchBox = 0;
    if (rest.hasSearchBox) {
      spaceForSearchBox = 60;
    } else {
      spaceForSearchBox = 0;
    }
    let spaceForTitle = 0;
    if (rest.title) {
      spaceForTitle = 90;
    } else {
      spaceForTitle = 0;
    }
    let windowHeight = 0;
    if (rest.windowHeight) {
      windowHeight = rest.windowHeight;
    } else {
      windowHeight = window.innerHeight;
    }
    let spaceForAppBar = 150;
    let rowH = 40; /* debe coincidir con Table.css > ibs_table_row_height */
    let spaceForColumnTitle = rowH;
    if (rest.fullscreen) {
      let total = (windowHeight - spaceForAppBar - spaceForSearchBox - spaceForTitle - spaceForColumnTitle);
      pageSize = parseInt(total / rowH) - 1/*ajuste*/;
    } else {
      pageSize = 5;
    }
    //-----------------------------------------------------------------------

    let dateFrom = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_from');
    let dateTo = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_to');
    let sortBy = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_sorting') || "dtCrea";
    let group = '';
    if (rest.group) {
      group = rest.group;
    }

    // record
    let record = {};
    record['searchText'] = newSearch;
    record['tooltip'] = tooltip;
    record['pageNo'] = newPageNo;
    record['pageSize'] = pageSize;
    record['dateFrom'] = dateFrom;
    record['dateTo'] = dateTo;
    record['sortBy'] = sortBy;
    record['filter'] = filter;
    record['filterHaving'] = filterHaving;
    record['group'] = group;
    if (addParams) {
      let params = addParams.split("&");
      params.forEach(function (item, index) {
        record[item.split("=")[0]] = item.split("=")[1];
      });
    }
    setProgress(true);
    Promise.all([FxFetch.doJsonX(txUrl.current, record)]).then((result) => {
      setProgress(false);
      if (result[0]) {
        setSearch(newSearch);
        setPageNo(newPageNo);
        setPageData(result[0]);
        if (newPageNo === 0) {
          if (result[0].length > 0) {
            r_is_empty.current = false;
          }
        }
      }
      if (onFetchPage) {
        onFetchPage();
      }
    });
  }

  const doSearch = () => {
    fetchPage(rf_search.current.getVal(), 0);
  }

  const getStyle = () => {
    let style = 'ibs_table';
    style += (rest.disabled ? ' disabled' : '')
    style += (rest.cursorDefault ? ' cursorDefault' : '')
    return style;
  }

  const doDisplayInfo = () => {
    try {
      Promise.all([getTotal()]).then((result) => {
        setInfoTotal(result[0]);
        setDlgInfo(true);
      });

    } catch (error) {
      FxLog.errorx("CxTable.doDisplayInfo", error);
    }
  }

  const isFilteredSorted = () => {
    let dateFrom = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_from');
    let dateTo = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_to');
    let sortBy = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_sorting');
    return Boolean(search || dateFrom || dateTo || sortBy);
  }

  const getTotal = () => {
    try {
      let dateFrom = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_from');
      let dateTo = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_to');
      let sortBy = sessionStorage.getItem('CxTable.' + (rest.id || '') + '_sorting') || "dtCrea";
      let group = '';
      if (rest.group) {
        group = rest.group;
      }
      // record
      let record = {};
      record['searchText'] = rf_search.current ? rf_search.current.getVal() || '' : '';
      record['tooltip'] = tooltip;
      record['pageNo'] = '0';
      record['pageSize'] = '1000000';
      record['dateFrom'] = dateFrom;
      record['dateTo'] = dateTo;
      record['sortBy'] = sortBy;
      record['filter'] = filter;
      record['filterHaving'] = filterHaving;
      record['group'] = group;
      if (addParams) {
        let params = addParams.split("&");
        params.forEach(function (item, index) {
          record[item.split("=")[0]] = item.split("=")[1];
        });
      }
      r_dlg_alert.current.setOpen(true);
      return Promise.all([FxFetch.doJsonX(txUrl.current, record)]).then((result) => {
        r_dlg_alert.current.setOpen(false);
        if (result[0]) {
          if (result[0].length > 0) {
            return result[0].length;
          }
        }
      });

    } catch (error) {
      FxLog.errorx("CxTable.getTotal", error);
    }
  }

  const openOptions = (e) => {
    if (e.ctrlKey) {
      if (onSpecialOptions) {
        onSpecialOptions();
      }
    } else {
      setDlgOptions(true);
    }
  }

  return (
    <div id='CxTable' style={{ display: 'block', margin: rest.margin }}>
      {rest.title && <CxHr title={rest.title} />}
      <div id='CxTable_header' style={{ display: rest.hasSearchBox ? 'flex' : 'none', justifyContent: 'space-between', flexWrap: 'wrap', margin: '0px' }}>
        <div id='CxTable_CxInput' className={'table_search_container'}>
          {rest.hasSearchBox &&
            <>
              <CxInput
                xref={rf_search}
                label={trans('field.search')}
                tooltip={tooltip}
                onKeyDown={(e) => { e.keyCode === 13 && doSearch(); }}
                onClear={clearSearch}
                onSearch={doSearch}
                width={window.innerWidth > MIN_WIDTH ? 'var(--ibs_control_width_lg)' : 'var(--ibs_control_width_sm)'}
                speechExternal={rest.speechExternal}
                onSpeechFinish={() => doSearch()}
              />
              <div style={{ height: '30px', width: '30px', display: 'flex', flexDirection: 'column', gap: 0 }}>
                {rest.showtip && size.isLarge && <a href={`https://iberical.com/ibericalhelp/${getStoreValue('StoreSession', 'txLocale').substring(0,2)}_${getStoreValue('StoreSession', 'txApp')}.php#section_mods_${'fsteps_search'}`} target="_blank" rel="noreferrer" >
                  {/* // 2024-05-07 - daq: sólo lo muestro si la parte servidor está preparada (usa XUtils.getQyCondition()), y en pantalla grande xq en el móvil se puede pulsar por error con el dedo */}
                  <CxIconButtonNew icon={<BiInfoCircle fontSize={19} style={{ margin: '0px 0 0 0px' }} />} />
                </a>}
                {rest.speechExternal && <CxIconButtonNew icon={<GiSpeaker fontSize={22} style={{ margin: '0px 0 0 2px' }} />} onClick={() => rf_search.current.doSpeech()} />}
              </div>
              {rest.action1 && 
                <CxIconButtonNew xref={rf_button_action1} 
                  icon={rest.action1.iconOff} 
                  classType={'table_button'} 
                  onClick={() => {
                    rf_button_action1.current.setIcon(rf_button_action1.current.getState()==='OFF' ? rest.action1.iconOn : rest.action1.iconOff)
                    rest.action1.onClick();
                  }
                  } 
                  tooltip={rest.action1.tooltip} 
                />
              }
            </>
          }
        </div>
        <div>
          {rest.children}
        </div>
      </div>
      <div id='CxTable_MuiTable'>
        <MuiTable className={getStyle()} {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell align={column.align} width={column.width} {...column.getHeaderProps()}>
                    {/* 2023-04-10 - daq: si se definió un ícono, se usa ése. */}
                    {column.iconID ?
                      <CxIconButton type={column.iconID} classType={'ibs_iconbutton_table_header'} onClick={column.onIconClick} tooltip={column.iconTooltip} />
                      :
                      column.render("Header")
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, iCell) => {
                    return (
                      <TableCell onClick={(e) => formUpdate(cell, e.ctrlKey)}
                        className={getRowColor(cell, iCell)}
                        align={getHeaderSetting(iCell, 'align')}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>

        <CxProgress wait={progress} />

        {!rest.origin && isFilteredSorted()
          && <span style={{ fontSize: '10px', color: 'red', fontWeight: 'bold', fontStyle: 'italic' }}>{trans('msg.list_filtered_sorted').toUpperCase()}</span>}

        <div id='CxTable_buttons' className={'table_button_container'} >
          {rest.addTableButtonsLeft}
          {onOptions && size.isLarge && <CxIconButtonNew icon={<IoSettingsOutline fontSize={BUTTON_FONTSIZE - 1} />} classType={'table_button'} onClick={openOptions} tooltip={trans('field.options')} />}
          {rest.colorRefs && <CxIconButtonNew icon={<IoInformationCircleOutline fontSize={BUTTON_FONTSIZE} />} classType={'table_button'} onClick={() => doDisplayInfo()} tooltip={trans('field.information')} />}
          <CxIconButtonNew icon={<IoPlaySkipBackCircleOutline fontSize={BUTTON_FONTSIZE} />} classType={'table_button'} onClick={goPageFirst} tooltip={trans('field.page_first')} />
          <CxIconButtonNew icon={<IoCaretBackCircleOutline fontSize={BUTTON_FONTSIZE} />} classType={'table_button'} onClick={goPagePrev} tooltip={trans('field.page_prev')} />
          <TextField variant="standard"
            value={pageNo + 1}
            disabled
            style={{ marginLeft: '5px', marginRight: '5px', width: '60px', height: '20px' }}
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
          />
          <CxIconButtonNew icon={<IoCaretForwardCircleOutline fontSize={BUTTON_FONTSIZE} />} classType={'table_button'} onClick={goPageNext} tooltip={trans('field.page_next')} />
          {rest.hasButtonAdd &&
            <CxIconButtonNew icon={<IoAddCircleOutline fontSize={BUTTON_FONTSIZE} />}
              classType={'table_button'}
              onClick={() => formAdd()}
              disabled={Boolean(rest.disabled)}
              tooltip={trans('field.add')}
            />}
          {rest.addTableButtonsRight}
        </div>

      </div>

      <CxDlgAlert xref={r_dlg_alert} />

      <CxDialog /* isLargeWindow={size.isLarge} */
        title={trans('field.options')}
        open={dlgOptions}
        onOk={() => optionsSelected()}
        onClose={() => setDlgOptions(false)}
      >
        {rest.optionalColumns &&
          <CxMultiCheck mode='checkbox' xref={rf_optional_columns}
            label={trans('field.additional_columns')}
            minRows={4}
            itemWidth="150px"
            list={rest.optionalColumns}
            width='var(--ibs_control_width_lg)'
          />
        }
        {rest.optionalSorting &&
          <CxCombo xref={rf_optional_sorting}
            label={`${trans('field.order_by')}`}
            list={rest.optionalSorting}
            width='var(--ibs_control_width_lg)'
            defaultValue={sessionStorage.getItem('CxTable.' + (rest.id || '') + '_sorting')}
          />
        }
        {rest.optionalFromTo &&
          <>
            <CxDate xref={rf_optional_from} label={trans('field.from')} defaultValue={sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_from')} />
            <CxDate xref={rf_optional_to} label={trans('field.to')} defaultValue={sessionStorage.getItem('CxTable.' + (rest.id || '') + '_date_to')} />
          </>
        }
      </CxDialog >

      <CxDialog /* isLargeWindow={size.isLarge} */
        title={trans('field.information')}
        open={dlgInfo}
        onClose={() => setDlgInfo(false)}
        height='250px'>
        <div style={{ height: '250px', width: '250px', }}>
          {infoTotal && <div>{trans('field.records') + ': '}{infoTotal}</div>} {/* // 2024-04-16 - daq: si hay demasiados registros, sucede un timeout entonces nosotros no mostramos esta línea. */}
          <hr className="table_cell_color_ref_hr" />
          <div>{trans('field.color_refs') + ': '}
            <ul className='table_cell_color_ref_ul'>
              {rest.colorRefs && rest.colorRefs.map(function (name, index) {
                return name === '' ? '' : <li className={name.split('|')[0]} key={index}>{name.split('|')[1]}</li>;
              })}
            </ul>
          </div>
        </div>
      </CxDialog>

      <CxDlgUpgrade xref={r_dlg_upgrade} />

    </div >
  );

};

export default CxTable;
