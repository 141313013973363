import React, { useRef } from 'react';
import GxForm from '../../gx/GxForm';
import { useTranslation } from '../../hx/useTranslation';
import { useNav } from '../../hx/useNav';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import { useSessionStorage } from '../../hx/useSessionStorage';
import CxSnackbar from '../../cx/CxSnackbar';
import { StoreRxAutoPs } from "../../gx/GxStore";


const MxPsRxAutoForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ navBack }] = useNav();

  // eslint-disable-next-line no-unused-vars
  const [storeRxAutoPs, setStoreRxAutoPs] = useSessionStorage('StoreRxAutoPs', StoreRxAutoPs);

  let r_form = React.useRef(null);

  const rf_txRegime = React.useRef(null);
  const rf_txCode = React.useRef(null);
  const rf_txNature = React.useRef(null);
  const rf_txActiveIngredient = React.useRef(null);
  const rf_txDrugStrength = React.useRef(null);
  const rf_txPharmaceuticalForm = React.useRef(null);
  const rf_txRouteAdministration = React.useRef(null);
  const rf_txDosageInformation = React.useRef(null);
  const rf_txDurationTreatment = React.useRef(null);
  const rf_txWithdrawalMilk = React.useRef(null);
  const rf_txWithdrawalMeat = React.useRef(null);
  const rf_txWithdrawalEggs = React.useRef(null);
  const rf_txDirections = React.useRef(null);

  const r_snack_ask_save = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data===null) { //nuevo registro
    }
  }
  const formSave = async (lastSaveRv) => {
    navBack();
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm 
        moduleName={`${trans('field.ps')}: ${trans('field.rx')}`}
        xref={r_form}
        url="pss/rx_auto" 
        filterIdField='itRxAuto' 
        filterIdValue={storeRxAutoPs.itRxAuto} 
        onFormLoad={formLoad} 
        onFormSave={formSave} 
        onFormDelete={formDelete} 
        >
        <CxHidden xref={useRef(null)} dbfield="t_rx_auto.inPs" value={storeRxAutoPs.inPs || ''} />

        <CxInput xref={rf_txRegime} label={trans('field.rx_regime')} dbfield="t_rx_auto.txRegime" maxLength='50' required />
        <CxInput xref={rf_txCode} label={trans('field.rx_national_code')} dbfield="t_rx_auto.txCode" maxLength='30' />
        <CxInput xref={rf_txNature} label={trans('field.rx_nature')} dbfield="t_rx_auto.txNature" maxLength='70' />
        <CxInput xref={rf_txActiveIngredient} label={trans('field.rx_active_ingredient')} dbfield="t_rx_auto.txActiveIngredient" maxLength='70' />
        <CxInput xref={rf_txDrugStrength} label={trans('field.rx_drug_strength')} dbfield="t_rx_auto.txDrugStrength" maxLength='30' />
        <CxInput xref={rf_txPharmaceuticalForm} label={trans('field.rx_pharmaceutical_form')} dbfield="t_rx_auto.txPharmaceuticalForm" maxLength='50' />
        <CxInput xref={rf_txRouteAdministration} label={trans('field.rx_route_administration')} dbfield="t_rx_auto.txRouteAdministration" maxLength='20' />
        <CxInput xref={rf_txDosageInformation} label={trans('field.rx_dosage_information')} dbfield="t_rx_auto.txDosageInformation" maxLength='50' />
        <CxInput xref={rf_txDurationTreatment} label={trans('field.rx_duration_treatment')} dbfield="t_rx_auto.txDurationTreatment" maxLength='70' />
        <CxInput xref={rf_txWithdrawalMilk} label={trans('field.rx_withdrawal_milk')} dbfield="t_rx_auto.txWithdrawalMilk" maxLength='70' />
        <CxInput xref={rf_txWithdrawalMeat} label={trans('field.rx_withdrawal_meat')} dbfield="t_rx_auto.txWithdrawalMeat" maxLength='70' />
        <CxInput xref={rf_txWithdrawalEggs} label={trans('field.rx_withdrawal_eggs')} dbfield="t_rx_auto.txWithdrawalEggs" maxLength='70' />
        <CxInput xref={rf_txDirections} label={trans('field.rx_directions')} dbfield="t_rx_auto.txDirections" maxLength='500' minRows={4} />
        
      </GxForm>

      <CxSnackbar xref={r_snack_ask_save} severity="error" value={trans('msg.save_before')} />
      
    </div>
  );

}

export default MxPsRxAutoForm;
