// Input para formularios
import React, { useState, useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';


const CxHidden = props => {
  const [myValue, setMyValue] = useState('');
  const [wasChanged, setChanged] = React.useState(false);

  React.useEffect(() => {
    if (props.value) {
      setMyValue(props.value);
    }
    // eslint-disable-next-line
  }, []);

  // para poder usar setVal desde el parent component con ref.current
  useImperativeHandle(props.xref, () => {
    return {
      getType, setVal, getVal, getFloatVal, getIsRequired, getLabel, getDbfield, isEmpty, wasChanged, setChanged,
    };
  });
  const getType = () => { return 'CxHidden' };
  const setVal = (newValue) => { setMyValue(newValue) };
  const getVal = () => { return myValue };
  const getIsRequired = () => { return false };
  const getLabel = () => { return '' };
  const getDbfield = () => { return props.dbfield };
  const isEmpty = () => { return myValue.length === 0 };
  const getFloatVal = () => { return parseFloat(getVal() || 0) };

  return (
    <div id='div_CxHidden' style={{
      marginRight: 'calc(-1 * var(--ibs_container_gap))', /* contraresta el gap entre controles */
    }}>
      <span
        value={myValue}
        dbfield={props.dbfield}
      />
      {props.show && <label style={{ color: 'red' }}>{`${props.dbfield||''}-->${myValue}`}</label>}
    </div>
  );
};

export default CxHidden;
