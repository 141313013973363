import React, { useRef } from 'react';
import CxDate from '../../cx/CxDate';
import CxHidden from '../../cx/CxHidden';
import CxInput from '../../cx/CxInput';
import CxChooser from '../../cx/CxChooser';
import CxState from '../../cx/CxState';
import CxStatic from '../../cx/CxStatic';
import CxTableAttachmentBlob from '../../cx/CxTableAttachmentBlob';
import GxForm from '../../gx/GxForm';
//import { useNav } from '../../hx/useNav';
import { useTranslation } from '../../hx/useTranslation';
// eslint-disable-next-line no-unused-vars
import { StoreClient, isApp } from "../../gx/GxStore";
import { useAccess } from '../../hx/useAccess';
import { useSessionStorage } from '../../hx/useSessionStorage';
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../../fx/FxLog';
import * as FxStr from '../../fx/FxStr';

const MxClientMultimediaForm = (props) => {
  const [{ trans }] = useTranslation();
  const [{ isFullAccess }] = useAccess(trans('field.clients'));
  //const [{ navBack }] = useNav();

  const [, updateState] = React.useState();
  const forceUIUpdate = React.useCallback(() => updateState({}), []);

  const [storeClient, setStoreClient] = useSessionStorage('StoreClient', StoreClient);
  
  let r_form = React.useRef(null);
  let r_table_attachment_blob = React.useRef(null);

  const rf_date = React.useRef(null);
  const rf_boShowInCp = React.useRef(null);


  React.useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const formLoad = async (data) => {
    if (data === null) { //nuevo registro
      rf_date.current.setValToday();
      
      // para poder guardar adjuntos cuando el registro es nuevo. 
      // se complementa en el servidor con LXXX.recSave(), que cuando es un registro nuevo, lo crea artificialmente para luego actualizarlo.
      // los registros que pudieran quedar huérfanos de n_xxx_blob porque el usuario se fué luego de adjuntar y sin guardar, se borrarán en el servidor LMaintenance.doOps().
      storeClient.inMultimedia = FxStr.getRandomID();
      Promise.all([setStoreClient(storeClient)]).then((result) => {
        r_form.current.setPropFilter('inMultimedia', storeClient.inMultimedia);
        r_table_attachment_blob.current.setInXxx(storeClient.inMultimedia);
      });

    }
  }
  const formSave = async (lastSaveRv) => {
    // se guardó exitosamente
    storeClient.inMultimedia = lastSaveRv.theValue;
    Promise.all([setStoreClient(storeClient)]).then((result) => {
      forceUIUpdate(); // 2024-03-06 - daq: para que muestre botón delete
      //navBack();
    });
  }
  const formDelete = async (rv) => {
  }

  return (
    <div>
      <GxForm
        moduleName={`${(isApp('vett') || isApp('spaw')) ? trans('field.patient') : trans('field.client')}: ${trans('field.file2')}`}
        xref={r_form}
        url="multimedia"
        filterIdField='inMultimedia'
        filterIdValue={storeClient.inMultimedia}
        onFormLoad={formLoad}
        onFormSave={isFullAccess() && formSave}
        onFormDelete={isFullAccess() && formDelete}
      >
        <CxHidden xref={useRef(null)} dbfield="inClient" value={storeClient.inClient || ''} />
        <CxHidden xref={useRef(null)} dbfield="n_multimedia.txLinkTable" value={(isApp('vett') || isApp('spaw')) ? 'n_animal' : 'n_client'} />
        <CxHidden xref={useRef(null)} dbfield="n_multimedia.txLinkId" value={(isApp('vett') || isApp('spaw')) ? storeClient.inAnimal : storeClient.inClient || ''} />

        {(isApp('invo') || isApp('opti')) && <CxStatic label={`${storeClient.txClient}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />}
        {(isApp('vett') || isApp('spaw')) && <CxStatic label={`${storeClient.txClient} / ${storeClient.txAnimal}`} border='none' fontcolor='var(--ibs_color_blue)' fontsize='12px' />}

        <CxDate xref={rf_date} label={trans('field.date')} dbfield={`n_multimedia.dtMultimedia`} required />

        <CxChooser xref={useRef(null)}
          label={trans('field.type')}
          dbfield="t_multimedia.txTypeMultimedia" dbfieldkey="n_multimedia.itMultimedia"
          table="t_multimedia"
          col_id="itMultimedia"
          col_tx="txTypeMultimedia"
          required
        />

        <CxInput xref={useRef(null)} label={trans('field.issue')} dbfield="n_multimedia.txIssue" maxLength={100} speech required />

        <CxInput xref={useRef(null)} label={trans('field.reference')} dbfield="n_multimedia.txReference" maxLength={50} />

        {!isApp('invo') && <CxState mode='checkbox' xref={rf_boShowInCp} label={trans('field.show_in_x','field.client_portal')} dbfield="n_multimedia.boShowInCp" />}

        <CxInput xref={useRef(null)} label={trans('field.notes')} dbfield="n_multimedia.txNotes" minRows={4} speech />
        
        <CxTableAttachmentBlob xref={r_table_attachment_blob}
          inClient={storeClient.inClient} 
          inXxx={storeClient.inMultimedia} 
          url={'multimedia'} 
          table={'n_multimedia_blob'} 
          fld_inXxx={'inMultimedia'} 
          fld_inXxxBlob={'inMultimediaBlob'} 
          fld_txXxxBlob={'txMultimediaBlob'} 
          printable
        />

      </GxForm>

    </div>
  );

}

export default MxClientMultimediaForm;
