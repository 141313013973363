import React, { useImperativeHandle } from "react";
// eslint-disable-next-line no-unused-vars
import * as FxLog from '../fx/FxLog';

const CxButton = props => {
  const { label, onClick, width, ...rest } = props;
  
  React.useEffect(() => {

    // eslint-disable-next-line
  }, []);

  const doClick = (e) => {
    e.preventDefault();
    props.onClick(e);
  };

  useImperativeHandle(props.xref, () => {
    return {
      getType, 
    };
  });
  const getType = () => { return 'CxButton' };

  return (
    <div>
      <button className="ibs_button" style={{ width: width||'100px' }} onClick={(e) => doClick(e)} {...rest} >
        {props.label}
      </button>
    </div>
  );

};

export default CxButton;
